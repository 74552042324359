import { useCallback, useEffect } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'

import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'

import { CheckoutFormPayload } from '../typings/checkoutFormTypings'
import { useTryToRequestServelessLeads } from './useTryToRequestServelessLeads'

export function useFormFilledServelessLeads() {
  const { getValues } = useFormContext<CheckoutFormPayload>()
  const { errors } = useFormState<CheckoutFormPayload>()
  const { name, email, phone } = useCheckoutParamsFromUrl()
  const isFilledByUrlParameters = name && email && phone

  const { tryToRequestServelessLeads } = useTryToRequestServelessLeads()

  const handleSubmitServelessLeads = useCallback(async () => {
    const { email, name, phone } = getValues()

    const payload = {
      fullName: name ?? null,
      phone: phone ?? null,
      email: email,
    }

    await tryToRequestServelessLeads(payload)
  }, [getValues, tryToRequestServelessLeads])

  // Verifica se os campos email e phone estão preenchidos e não possuem erros
  const shouldCallServelessLeads = useCallback(() => {
    return (
      getValues('email') &&
      !errors.email &&
      ((getValues('phone') && !errors.phone) || !getValues('phone'))
    )
  }, [getValues, errors])

  useEffect(() => {
    if (isFilledByUrlParameters) return
    const emailInput = document.querySelector('input[name="email"]')
    if (emailInput) {
      const emailBlurHandler = () => {
        if (shouldCallServelessLeads()) {
          handleSubmitServelessLeads()
        }
      }
      emailInput.addEventListener('blur', emailBlurHandler)

      // Adicione um ouvinte de evento de entrada para verificar as alterações no campo de email
      // emailInput.addEventListener('input', () => {
      //   if (shouldCallServelessLeads()) {
      //     handleSubmitServelessLeads()
      //   }
      // })

      return () => {
        emailInput.removeEventListener('blur', emailBlurHandler)
        // emailInput.removeEventListener('input', emailBlurHandler)
      }
    }
  }, [shouldCallServelessLeads, handleSubmitServelessLeads])

  useEffect(() => {
    if (isFilledByUrlParameters) return

    const phoneInput = document.querySelector('input[name="phone"]')
    if (phoneInput) {
      const phoneBlurHandler = () => {
        if (shouldCallServelessLeads()) {
          handleSubmitServelessLeads()
        }
      }
      phoneInput.addEventListener('blur', phoneBlurHandler)

      // // Adicione um ouvinte de evento de entrada para verificar as alterações no campo de telefone
      // phoneInput.addEventListener('input', () => {
      //   if (shouldCallServelessLeads()) {
      //     handleSubmitServelessLeads()
      //   }
      // })

      return () => {
        phoneInput.removeEventListener('blur', phoneBlurHandler)
        //phoneInput.removeEventListener('input', phoneBlurHandler)
      }
    }
  }, [shouldCallServelessLeads, handleSubmitServelessLeads])
}
