export function waitUntilGtagIsLoaded() {
  return new Promise((resolve, reject) => {
    const timeStart = Date.now()
    const TIMEOUT = 5000

    const isLoaded = () => {
      const differenceInTimeSinceStart = Date.now() - timeStart
      if (differenceInTimeSinceStart > TIMEOUT) {
        reject(false)
        return
      }

      const isInjected = typeof gtag === 'function'
      const timeLimit = 500

      if (isInjected) {
        resolve(true)
        return
      }

      setTimeout(isLoaded, timeLimit)
    }

    isLoaded()
  })
}
