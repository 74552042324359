import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useFormContext } from 'react-hook-form'

import { usePurchasedMixPanel } from 'src/features/analytics/hooks/usePurchasedMixPanel'
import { usePurchasedPixel } from 'src/features/analytics/hooks/usePurchasedPixel'
import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'
import { CheckoutFormPayload } from 'src/features/checkout/typings/checkoutFormTypings'
import { isCheckoutFormPayloadWithPix } from 'src/features/checkout/utils/checkoutFormTypeGuards'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'

import { paymentsApi } from '../apis/paymentApi'
import {
  RequestVerificationPixPaymentResponse,
  TryRequestPixPaymentVerification,
} from '../typings/paymentTypings'
import { PixPaymentDetailsProps } from '../typings/useTryToCreateOrderPixTypings'
import { requestPixBodyFactories } from '../utils/requestPixBodyFactories'

interface PixPaymentVerificationResponse {
  TryToRequestPixPaymentVerification: UseMutateAsyncFunction<
    RequestVerificationPixPaymentResponse,
    unknown,
    PixPaymentDetailsProps,
    unknown
  >
}

export function useTryToRequestPixPaymentVerification(): PixPaymentVerificationResponse {
  const { getValues } = useFormContext<CheckoutFormPayload>()
  const { product } = useProduct()
  const { offer } = useOffer()
  const purchasedPixel = usePurchasedPixel()
  const purchaseMixPanel = usePurchasedMixPanel()
  const formPayload = getValues()

  const onPurchaseSuccess = () => {
    purchasedPixel(formPayload)
    purchaseMixPanel()
  }

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
  const isDirectCheckout = useAtomValue(checkoutAtoms.isDirectCheckout)

  const { mutateAsync } = useMutation({
    mutationFn: async (pixData: PixPaymentDetailsProps) => {
      if (!isCheckoutFormPayloadWithPix(formPayload) || !product)
        throw new Error('invalid payload')

      const body: TryRequestPixPaymentVerification =
        await requestPixBodyFactories.toVerificationPixPayment(
          formPayload,
          pixData,
          isNewCheckout,
          isDirectCheckout,
          offer
        )

      return paymentsApi.tryToVerificationPixPayment(body)
    },
    onSuccess: ({ status, redirectUrl }) => {
      const isStatusPaid = status === 'paid'
      const isRedirectUrl = redirectUrl

      if (isStatusPaid && isRedirectUrl) {
        onPurchaseSuccess()
        window.location.href = redirectUrl
      }
    },
  })

  return {
    TryToRequestPixPaymentVerification: mutateAsync,
  }
}
