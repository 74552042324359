import { intervalToDuration, isBefore } from 'date-fns'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { useInterval } from 'react-use'

import { getDisplayTimer } from 'src/utils/timer'

import { usePixCountdownAtoms } from '../atoms/usePixCountdownAtoms'

interface CountdownProps {
  minutes: string
  seconds: string
}

interface usePixCountdownProps {
  ExpiresAt: Date
}

export function usePixCountdown({ ExpiresAt }: usePixCountdownProps) {
  const [countdown, setCountdown] = useState<CountdownProps>(
    {} as CountdownProps
  )

  const [isFinalizedCountdown] = useState(false)

  const [, setIsNewRequestQrCode] = useAtom(
    usePixCountdownAtoms.isNewRequestQrCode
  )

  const handleUpdateCountDown = useCallback(() => {
    const dateFromNow = new Date()
    const isExpiredPixTime = isBefore(ExpiresAt, dateFromNow)

    if (isExpiredPixTime) {
      setIsNewRequestQrCode(true)

      setCountdown({ minutes: '00', seconds: '00' })
      return
    }

    setIsNewRequestQrCode(false)

    const duration = intervalToDuration({
      start: dateFromNow,
      end: ExpiresAt,
    })

    const { minutes, seconds } = getDisplayTimer({
      minutes: duration.minutes,
      seconds: duration.seconds,
    })
    return setCountdown({ minutes, seconds })
  }, [ExpiresAt])

  useEffect(handleUpdateCountDown, [])

  useInterval(handleUpdateCountDown, !isFinalizedCountdown ? 1000 : null)

  return countdown
}
