import { atom } from 'jotai'

export const isCopyQrCode = atom(false)

export const isErrorPaymentPix = atom(false)

export const isFormDataValid = atom(true)

export const PixPaymentAtoms = {
  isErrorPaymentPix,
  isCopyQrCode,
  isFormDataValid,
}
