import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Box, Button, Flex, Text } from '@queimadiaria/ui'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai'
import Image from 'next/image'
import { FC, useCallback, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useFormContext } from 'react-hook-form'

import { env } from 'src/configs/env'
import { PaymentOptions } from 'src/features/checkout/components/PaymentOptions'
import { useCheckoutForm } from 'src/features/checkout/hooks/useCheckoutForm'
import { CrossSellCheckoutErrorMessage } from 'src/features/crossSellCheckout/components/CrossSellCheckoutErrorMessage'
import { useIsCrossSellCheckout } from 'src/features/crossSellCheckout/hooks/useIsCrossSellCheckout'
import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'
import { useIsOfferFormat } from 'src/features/plan/offer/hooks/useIsOfferFormat'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'
import { formatCurrency } from 'src/utils/currency'
import { isMonthlySubscriptionOrDirectFallback } from 'src/utils/isMonthlySubscriptionOrDirectFallback'

import { checkoutAtoms } from '../../checkout/atoms/checkoutAtoms'
import { CheckoutErrorMessage } from '../../checkout/components/CheckoutErrorMessage'
import { Installments } from '../../checkout/typings/checkoutFormTypings'
import { paymentMethodToRenderTheRecaptcha } from '../constants/submitDetailsConstants'
import { useImages } from '../hooks/useImages'

interface SubmitDetailsProps {
  isInstallmentsEnabled: boolean
  submitButtonLabel: string
}

export const SubmitDetailsContainerFromNewCheckout: FC<SubmitDetailsProps> = ({
  isInstallmentsEnabled,
  submitButtonLabel,
}) => {
  const { product } = useProduct()
  const isProduct329 = product?.id.toString() === '329'

  return (
    <>
      {isProduct329 ? (
        <SubmitDetailsToProduct329
          isInstallmentsEnabled={isInstallmentsEnabled}
          submitButtonLabel={submitButtonLabel}
        />
      ) : (
        <SubmitDetails
          isInstallmentsEnabled={isInstallmentsEnabled}
          submitButtonLabel={submitButtonLabel}
        />
      )}
    </>
  )
}

const SubmitDetails: FC<SubmitDetailsProps> = ({
  isInstallmentsEnabled,
  submitButtonLabel,
}) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext()
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const isCrossSellCheckout = useIsCrossSellCheckout()
  const isCreatingOrder = useAtomValue(paymentAtoms.isCreatingOrder)

  const isSubmitButtomDisabled = useAtomValue(
    checkoutAtoms.isSubmitButtomDisabled
  )
  const isPaymentByPix = useAtomValue(checkoutAtoms.isPaymentByPix)
  const hasErrorCreatingOrder = useAtomValue(paymentAtoms.hasErrorCreatingOrder)

  const isOfferFormat = useIsOfferFormat()

  const form = useCheckoutForm()

  const { watch } = form

  const paymentMethod = watch('paymentMethod')

  const [, setRecaptchaRef] = useAtom(checkoutAtoms.recaptchaRef)

  const onChangeRecaptcha = useCallback(() => {
    setRecaptchaRef(recaptchaRef)
  }, [])

  return (
    <Flex
      sx={{
        flexDirection: 'column',

        marginBlockEnd: !isPaymentByPix
          ? [null, '32px', '32px', '32px']
          : '0px',
        background: isPaymentByPix && '#FAFAFA',
      }}
    >
      {isCrossSellCheckout ? (
        <CrossSellCheckoutErrorMessage />
      ) : (
        <CheckoutErrorMessage />
      )}

      {(isPaymentByPix || hasErrorCreatingOrder) && <PaymentOptions />}

      <Flex
        sx={{
          alignItems: 'flex-end',
          justifyContent: ['center', 'center', 'flex-start'],
          flexFlow: 'wrap',
        }}
      >
        {isOfferFormat ? <OfferDescription /> : null}
        <ContainerPrice
          isOfferFormat={isOfferFormat}
          isPaymentByPix={isPaymentByPix}
          isCrossSellCheckout={isCrossSellCheckout}
        >
          {!isOfferFormat ? (
            <OfferDetails
              isPaymentByPix={isPaymentByPix}
              isInstallmentsEnabled={isInstallmentsEnabled}
            />
          ) : null}

          {paymentMethodToRenderTheRecaptcha.includes(paymentMethod) && (
            <>
              <ReCAPTCHA
                ref={recaptchaRef}
                style={{
                  marginBlockStart: 8,
                  marginBlockEnd: errors && errors.recaptchaToken ? 2 : 24,
                }}
                sitekey={`${env.RECAPTCHA_SITE_KEY}`}
                onChange={(value: string | null) => {
                  onChangeRecaptcha()
                  setValue('recaptchaToken', value)
                }}
              />
              {errors && errors.recaptchaToken && (
                <FormHelperText>{errors.recaptchaToken.message}</FormHelperText>
              )}
            </>
          )}

          {!isPaymentByPix && (
            <Button
              loading={isCreatingOrder}
              children={
                !isCreatingOrder && (
                  <Text
                    as='span'
                    variant='subheadline-1'
                    style={{
                      fontSize: '16px',
                      lineHeight: '150%',
                      letterSpacing: '0.0125em',
                    }}
                  >
                    {submitButtonLabel}
                  </Text>
                )
              }
              disabled={isSubmitButtomDisabled}
              size='large'
              backgroundColor='#005FF2'
              sx={{
                '&:disabled': {
                  justifyContent: 'center',
                  '&:hover': {
                    opacity: 1,
                  },
                },
              }}
            />
          )}
        </ContainerPrice>
        {!isPaymentByPix && (
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              mx: [0, 0, 'medium'],
              my: ['medium', 'medium', 0],
            }}
          ></Flex>
        )}
        {isCrossSellCheckout && (
          <Stamp src='/assets/selossl.svg' alt='Site Protegido com SSL' />
        )}
      </Flex>
    </Flex>
  )
}

interface ContainerPriceProps {
  isOfferFormat: boolean
  isPaymentByPix: boolean
  isCrossSellCheckout: boolean
}

const ContainerPrice = styled(Flex)<ContainerPriceProps>`
  flex-direction: column;
  margin-block-start: 24px
    ${props =>
      props.isOfferFormat &&
      css`
        margin-block-start: 8px;
      `};
  ${props =>
    props.isPaymentByPix &&
    css`
      margin-block-start: 0px;
      align-items: center;
    `}
  width: ${props => (props.isCrossSellCheckout ? '50%' : '100%')};
  padding: ${props => (props.isPaymentByPix ? '0px' : '0px 32px')};
`

interface OfferDetailsProps {
  isInstallmentsEnabled: boolean
  isPaymentByPix?: boolean
}
const OfferDetails: FC<OfferDetailsProps> = ({
  isInstallmentsEnabled,
  isPaymentByPix,
}) => {
  const { watch } = useFormContext<Installments>()
  const isCrossSellCheckout = useIsCrossSellCheckout()
  const { product } = useProduct()
  const { logo } = useImages()
  const { offer } = useOffer()

  const installmentsCount = watch('installments') || offer?.installments
  const installment = offer?.installmentsItems.find(
    item => item.installment === installmentsCount
  )

  const isSubscriptionMonthly = isMonthlySubscriptionOrDirectFallback(product)

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)

  return (
    <Box
      sx={
        isPaymentByPix && isNewCheckout
          ? {
              background: '#FAFAFA',
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {}
      }
    >
      <Text
        variant='subheadline-3'
        style={
          !isCrossSellCheckout
            ? { fontWeight: 500, fontSize: '14px', lineHeight: '16px' }
            : {}
        }
      >
        Total
      </Text>
      <Box
        sx={{
          display: 'flex',
          marginBlockEnd: !isPaymentByPix && '16px',
        }}
      >
        <PlanSmallLogoContainer>
          <Image
            aria-hidden='true'
            layout='fill'
            src={logo}
            alt='Logo do produto'
          />
        </PlanSmallLogoContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text
            style={
              !isCrossSellCheckout
                ? { fontWeight: 400, fontSize: '12px', lineHeight: '16px' }
                : {}
            }
            color={!isCrossSellCheckout ? '#757575' : ''}
            variant='body-2'
          >
            {offer?.name || product?.publicName || 'Queima Diária'}
          </Text>
          {isInstallmentsEnabled && installment ? (
            <Text
              style={
                !isCrossSellCheckout
                  ? { fontWeight: 500, fontSize: '16px', lineHeight: '24px' }
                  : {}
              }
              variant='headline-3'
            >
              {!isSubscriptionMonthly && `${installment?.installment}x de `}
              {formatCurrency(parseFloat(installment.price))}
              {!isSubscriptionMonthly ? '/ano' : '/mês'}
            </Text>
          ) : (
            <Text
              style={
                !isCrossSellCheckout
                  ? { fontWeight: 500, fontSize: '16px', lineHeight: '24px' }
                  : {}
              }
              variant='headline-3'
            >
              {formatCurrency(offer?.fullPrice ?? 0)}
              {!isSubscriptionMonthly ? '/ano' : '/mês'}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  )
}
const OfferDescription: FC = () => {
  const { offer } = useOffer()

  return (
    <Box
      sx={{
        margin: '16px 0 16px 0',
        textAlign: 'justify',
      }}
    >
      <Text variant='body-2'>{offer?.offerFormat?.description}</Text>
    </Box>
  )
}

const SubmitDetailsToProduct329: FC<SubmitDetailsProps> = ({
  isInstallmentsEnabled,
  submitButtonLabel,
}) => {
  return (
    <>
      <Box
        sx={{
          marginTop: '16px',
          textAlign: 'justify',
          padding: '0px 32px',
        }}
      >
        <Text variant='body-2'>
          Essa é uma oferta por tempo limitado. Você está recebendo um desconto
          especial de 10% no plano anual da Queima Diária, por isso pagará 12x
          de R$26,91. Você terá 30 dias de garantia, após esse período, para
          evitar interrupção no seu acesso à plataforma e aos programas de
          exercício, o seu plano será continuado automaticamente. Mas se por
          qualquer razão você não quiser continuar, poderá cancelar facilmente a
          sua assinatura durante os primeiros 30 dias e nada mais será cobrado
          no seu cartão.
        </Text>
      </Box>
      <SubmitDetails
        isInstallmentsEnabled={isInstallmentsEnabled}
        submitButtonLabel={submitButtonLabel}
      />
    </>
  )
}

const FormHelperText = styled(Text)`
  font-weight: 400;
  font-family: 'Graphik', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #b00020;
  margin-block-end: 24px;
`

const Stamp = styled.img`
  height: 50px;
`

export const PlanSmallLogoContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  margin-inline-end: 8px;
`
