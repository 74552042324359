import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useFormContext } from 'react-hook-form'

import { SerializedApiError } from 'src/infra/typings/globalTypings'

import { changeEmailApi } from '../apis/changeUserEmailApi'
import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { ToastCustom } from '../components/Toast'
import { CheckoutFormPayload } from '../typings/checkoutFormTypings'

export function usePutChangeEmail() {
  const customerData = useAtomValue(checkoutAtoms.customerData)
  const { getValues, setValue } = useFormContext<CheckoutFormPayload>()

  const { data, mutateAsync } = useMutation({
    mutationFn: async () => {
      const body = {
        oldEmail: customerData.email,
        newEmail: getValues('email'),
      }

      return changeEmailApi.putChangeUserEmailApi(body)
    },
    onError: (error: SerializedApiError) => {
      setValue('email', customerData.email)
      if (error.code === '23505') {
        ToastCustom({
          type: 'error',
          message: 'E-mail já cadastrado. Tente um diferente.',
        })
      } else {
        ToastCustom({
          type: 'error',
          message:
            'Não foi possível alterar o e-mail. Entre em contato no chat.',
        })
      }
    },
  })

  return {
    changeEmailData: data,
    tryChangeUserEmail: mutateAsync,
  }
}
