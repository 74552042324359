const phoneFixMask = (phone: string) => {
  if (phone.length < 3) return '(' + phone + ')'
  if (phone.length > 2 && phone.length < 7)
    return '(' + phone.slice(0, 2) + ') ' + phone.slice(2)
  return (
    '(' +
    phone.slice(0, 2) +
    ') ' +
    phone.slice(2, 6) +
    '-' +
    phone.slice(6, 10)
  )
}
const phoneCellMask = (phone: string) => {
  if (phone.length < 3) return '(' + phone + ')'
  if (phone.length > 2 && phone.length < 8)
    return '(' + phone.slice(0, 2) + ') ' + phone.slice(2)
  return (
    '(' +
    phone.slice(0, 2) +
    ') ' +
    phone.slice(2, 7) +
    '-' +
    phone.slice(7, 11)
  )
}
export const phoneMask = (phone: string) => {
  if (!phone) return ''
  if (phone.length > 10) return phoneCellMask(phone)
  return phoneFixMask(phone)
}
