import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { useInterval } from 'react-use'

import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'
import { usePaymentDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/modules/payment/usePaymentDigitalCheckoutContext'

import { PixPaymentDetailsProps } from '../typings/useTryToCreateOrderPixTypings'
import { useTryToRequestPixPaymentVerification } from './useTryToRequestPixPaymentVerification'

export function useTryToVerificationPixPayment(
  pixPaymentData: PixPaymentDetailsProps | undefined
) {
  const [isPaymentByPix] = useAtom(checkoutAtoms.isPaymentByPix)
  const { selectedMethodPayment } = usePaymentDigitalCheckoutContext()
  const { TryToRequestPixPaymentVerification } =
    useTryToRequestPixPaymentVerification()
  const fiveSeconds = 5000

  const handlePixPaymentStatusRequest = async () => {
    if (pixPaymentData) await TryToRequestPixPaymentVerification(pixPaymentData)
  }

  const isPixQrCodeExistsAndPixIsSelected = useCallback(
    (pixData: PixPaymentDetailsProps | undefined, isPaymentByPix: boolean) => {
      const isPixQrCodeExists = pixData && pixData.qrCode
      const isPixSelected = isPaymentByPix || selectedMethodPayment === 'pix'

      if (isPixQrCodeExists && isPixSelected) {
        return true
      }

      return false
    },
    [selectedMethodPayment]
  )

  useInterval(
    handlePixPaymentStatusRequest,
    isPixQrCodeExistsAndPixIsSelected(pixPaymentData, isPaymentByPix)
      ? fiveSeconds
      : null
  )
}
