export interface PlanDetails {
  id: string
}

interface Pixel {
  ids: string[]
}

export enum PlanPeriod {
  MONTHLY = 30,
  YEARLY = 360,
}

export enum Subscription {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export interface Plan {
  id: number
  disclaimer?: string
  name: string
  alias: string
  metadata: {
    installments: number
    checkout: {
      productName: string
      analytics: string[]
      pixel: Pixel | undefined
      origin: string
    }
    newBanner: string
  }
  price: number
  period: PlanPeriod
  banner: string
  bannerMobile: string
  footerBadges: string
  logo: string
  sideBanner: string
  subscriptionType: 'yearly' | 'monthly'
}
