import { Flex } from '@queimadiaria/ui'
import React from 'react'

import { usePixCountdown } from '../hooks/usePixCountdown'
import { Text } from './styles'

export interface PixCountdownProps {
  endsAt: Date
}

export const PixCountdown: React.FC<PixCountdownProps> = ({ endsAt }) => {
  const countdown = usePixCountdown({
    ExpiresAt: endsAt,
  })
  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Text variant='body-2'>
        {`Código válido por
        ${countdown.minutes}:${countdown.seconds}`}
      </Text>
    </Flex>
  )
}
