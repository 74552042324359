import { apiServelessLeads } from 'src/infra/api'
import { createUnknownSerializedApiError } from 'src/utils/createUnknownSerializedApiError'

import { fromCurrentUserErrorToSerializedApiError } from '../utils/fromCurrentUserErrorToSerializedApiError'

export interface TryCreateServerlessActiveCampaignleadPayload {
  // customerId: string
  fullName: string | undefined
  phone: string | undefined
  email: string | undefined
  productId: number | undefined
  offerId: number | null
  src: string | null
  referrerPagePlan: string | undefined
  fullOrigin: string
}

async function tryCreateServerlessActiveCampaignlead(
  payload: TryCreateServerlessActiveCampaignleadPayload
) {
  return apiServelessLeads
    .url('/customer/create')
    .post(payload)
    .internalError(error => {
      throw fromCurrentUserErrorToSerializedApiError(error.json)
    })
    .unauthorized(error => {
      throw fromCurrentUserErrorToSerializedApiError(error.json)
    })
    .badRequest(error => {
      throw fromCurrentUserErrorToSerializedApiError(error.json)
    })
    .fetchError(() => {
      throw createUnknownSerializedApiError()
    })
    .json()
}

export const servelessCheckoutApi = {
  tryCreateServerlessActiveCampaignlead,
}
