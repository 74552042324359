import { useAtom } from 'jotai'
import { useCallback } from 'react'

import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { usePutChangeEmail } from './usePutChangeEmail'

export function useUpdateLoginEmail() {
  const [, setIsUpdateLoginEmail] = useAtom(checkoutAtoms.isUpdateLoginEmail)
  const { tryChangeUserEmail } = usePutChangeEmail()

  const handleIsUpdateLoginEmail = useCallback(async () => {
    setIsUpdateLoginEmail(
      currentIsUpdateLoginEmail => !currentIsUpdateLoginEmail
    )
  }, [])

  const handleEmailChange = useCallback(async () => {
    try {
      await tryChangeUserEmail()
    } finally {
      handleIsUpdateLoginEmail()
    }
  }, [])

  return { handleIsUpdateLoginEmail, handleEmailChange }
}
