import { Box } from '@queimadiaria/ui'
import { useEffect, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { CheckoutFormPayloadOfAllTypes } from 'src/features/checkout/typings/checkoutFormTypings'
import { couponModule } from 'src/modules/coupon'
import { Coupon } from 'src/modules/coupon/types/coupon.types'
import { CouponFns } from 'src/modules/coupon/types/couponModule.types'
import { validatorCPF } from 'src/utils/string'

import { useCupomDigitalCheckoutStore } from '../../hooks/useCupomDigitalCheckoutStore'

type CupomDigitalCheckoutProps = {
  productSellGroupId: number
  offerId: number
}

export function CupomDigitalCheckout({
  offerId,
  productSellGroupId,
}: CupomDigitalCheckoutProps) {
  const couponRef = useRef<CouponFns>(null)
  const {
    setSelectedCupomDigitalCheckout,
    focusedDigitalCheckout,
    selectedCupomDigitalCheckout,
  } = useCupomDigitalCheckoutStore()
  const { control, watch } = useFormContext<CheckoutFormPayloadOfAllTypes>()

  const [emailUserData, documentUserData] = watch(['email', 'document'])

  useEffect(() => {
    const verifyEmail =
      !!emailUserData &&
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailUserData)

    const verifyDocument = !!documentUserData && validatorCPF(documentUserData)

    if (!verifyEmail || !verifyDocument || focusedDigitalCheckout) return
    const userData = {
      document: documentUserData,
      email: emailUserData,
    }
    couponRef.current?.updateCouponUserData(userData)
  }, [emailUserData, documentUserData, focusedDigitalCheckout])

  return (
    <Controller
      name='coupon'
      control={control}
      defaultValue={null}
      render={({ field: { onChange } }) => {
        const onChangeCoupon = (coupon: Coupon | undefined) => {
          setSelectedCupomDigitalCheckout(coupon || null)
          onChange(coupon || null)
        }

        const { component: CouponComponent } = couponModule({
          provider: 'digital',
        })

        return (
          <Box style={{ marginTop: 32, maxWidth: 420 }}>
            <CouponComponent
              couponRef={couponRef}
              productSellGroupId={productSellGroupId}
              coupon={selectedCupomDigitalCheckout || undefined}
              onChangeCoupon={onChangeCoupon}
              offerId={offerId}
            />
          </Box>
        )
      }}
    />
  )
}
