import { nanoid } from 'nanoid'
import { stringify } from 'qs'

import { getQueryParamsFromUrl } from 'src/utils/url'

import { MarketingBillet } from '../typings/billetUrlInfosTyping'

export function addMarketingParamsToRedirectUrl(
  redirectUrl: string,
  productId: string,
  productName: string,
  revenue: number,
  qpf_token?: string
) {
  const [url, params] = getQueryParamsFromUrl(redirectUrl)

  const paramsToAppend = {
    qpf_token: qpf_token,
    orderId: nanoid(),
    productId,
    productName,
    revenue,
    ...params,
  }

  return `${url}?${stringify(paramsToAppend)}`
}

export function addParamsToRedirectUrlBillet(
  redirectUrl: string,
  marketing: MarketingBillet,
  authToken?: string
) {
  const [url, params] = getQueryParamsFromUrl(redirectUrl)

  const paramsToAppend = {
    qpf_token: marketing.qpf_token,
    orderId: nanoid(),
    productId: marketing.productId,
    productName: marketing.productName,
    authToken: authToken,
    ...params,
  }

  return `${url}?${stringify(paramsToAppend)}`
}
