import { useMutation } from '@tanstack/react-query'

import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'

import {
  servelessCheckoutApi,
  TryCreateServerlessActiveCampaignleadPayload,
} from '../apis/servelessCheckoutApi'
import { usePlanIdFromRoute } from './usePlanIdFromRoute'

interface TryToRequestPayloadServelessLeads {
  fullName?: string
  phone?: string
  email?: string
  referrerPagePlan?: string
}

export function useTryToRequestServelessLeads() {
  const planId = usePlanIdFromRoute()
  const { offer } = useOffer()
  const { src } = useCheckoutParamsFromUrl()

  const { mutateAsync } = useMutation({
    mutationFn: async (payload: TryToRequestPayloadServelessLeads) => {
      const body: TryCreateServerlessActiveCampaignleadPayload = {
        fullName: payload.fullName ?? undefined,
        phone: payload.phone ?? undefined,
        email: payload.email,
        productId: Number(planId),
        offerId: offer ? parseInt(offer.id.toString()) : null,
        src: src ?? null,
        referrerPagePlan: payload.referrerPagePlan ?? undefined,
        fullOrigin: window.location.href,
      }

      return await servelessCheckoutApi.tryCreateServerlessActiveCampaignlead(
        body
      )
    },
    onSuccess: async response => {
      return response
    },
  })

  return { tryToRequestServelessLeads: mutateAsync }
}
