import { useCallback, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useInterval } from 'react-use'

import { CheckoutFormPayload } from '../typings/checkoutFormTypings'

export function useOnContactInfoFilledCallback(callback: () => void) {
  const isCallbackCalled = useRef(false)
  const { getValues } = useFormContext<CheckoutFormPayload>()

  const verifyIfContactInfoWasFilled = useCallback(() => {
    if (isCallbackCalled.current) return
    const values = getValues(['name', 'email', 'document', 'phone'])

    if (hasEmptyValues(Object.values(values))) return
    callback()
    isCallbackCalled.current = true
  }, [callback, getValues])

  useInterval(verifyIfContactInfoWasFilled, 3000)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasEmptyValues = (values: any[]) => {
  return !values.every(value => Boolean(value))
}
