import { CheckoutFormPayload } from 'src/features/checkout/typings/checkoutFormTypings'
import { PaymentMethods } from 'src/features/checkout/typings/legacyCheckoutTypings'
import { Subscription } from 'src/features/checkout/typings/planTypings'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'

import { mixpanel } from '../utils/mixpanel'
import { capitalize } from '../utils/pixelHelpers'

export type MixPanelEvents = 'Purchase' | 'onPurchaseClick'

type TOnEventProps = {
  keyEvent?: MixPanelEvents
  paymentMethod?: CheckoutFormPayload['paymentMethod']
}

export function usePurchasedMixPanel() {
  const queryParams = useCheckoutParamsFromUrl()
  const { product } = useProduct()
  const { offer } = useOffer()

  const { src } = queryParams

  const onEvent = (props?: TOnEventProps) => {
    if (!product) return

    const event = {
      plan: {
        id: product.id.toString(),
        name: product.publicName,
      },
      src: src ? { src } : {},
      value: offer?.fullPrice,
      currency: 'BRL',
      subscription: capitalize(Subscription.YEARLY),
      paymentMethod: capitalize(
        props?.paymentMethod || PaymentMethods.CreditCard
      ),
      ...queryParams,
    }

    mixpanel().track(props?.keyEvent || 'Purchase', event)
  }

  return onEvent
}
