import jwt from 'jsonwebtoken'

import { env } from 'src/configs/env'
import { CheckoutFormPayloadWithBillet } from 'src/features/checkout/typings/checkoutFormTypings'
import { Offer } from 'src/features/plan/offer/typings/offerTypings'
import { Product } from 'src/features/plan/product/typings/productTypings'

import { TryToCreateOrderResponsePayload } from '../apis/legacyPaymentApi'
import { BilletOrder } from '../typings/billetUrlInfosTyping'
export function setBilletOrder(
  offer: Offer,
  responseBilletOrder: TryToCreateOrderResponsePayload,
  user: CheckoutFormPayloadWithBillet,
  product: Product
) {
  const billetOrder = {
    value: offer.fullPrice,
    offerName: offer.name,
    billetBarCode: responseBilletOrder.boletoBarCode,
    billetUrl: responseBilletOrder.boletoUrl,
    productName: product.publicName,
    userBillet: {
      name: user.name,
      email: user.email,
      phone: user.phone.replace(/[^\d]+/g, ''),
    },
    pixelBillet: product.pixelBillet,
  }

  return billetOrder
}

export function setMarketingBillet(product: Product, offer: Offer) {
  const marketingBillet = {
    productId: product.id.toString(),
    productName: product.publicName,
    revenue: offer.fullPrice,
  }

  return marketingBillet
}

export function generateJwtBilletOrder(billet: BilletOrder) {
  if (env.JWT_BIT_SECRET) {
    const token = jwt.sign({ billet }, env.JWT_BIT_SECRET, {
      expiresIn: 60 * 60,
    })
    if (token) {
      return token
    }
  } else {
    throw new Error('Dont Generate JsonWebToken!')
  }
}
