import { useSizeScreen } from 'src/ui/hooks/useSizeScreen'

import { CheckboxOrderBump } from '../../CheckboxOrderBump/CheckboxOrderBump'
import { TOrderBumpCheckoutTemplateProps } from '..'
import styles from './styles.module.css'

export function TemplateB({
  orderBumpData,
  checked,
  onCheckChange,
}: TOrderBumpCheckoutTemplateProps) {
  const {
    title,
    webLink,
    mobileLink,
    description,
    confirmationButtonText,
    backgroundColor,
    textColor,
    primaryColor,
    secondaryColor,
    isActive,
  } = orderBumpData

  const { isBigger } = useSizeScreen()

  const isMobile = !isBigger(440)

  if (!isActive) return null

  return (
    <>
      {!isMobile && <div className={styles.divider} />}
      <div
        className={styles.container}
        style={{ backgroundColor, color: textColor, borderColor: primaryColor }}
      >
        <div
          className={styles.checkContent}
          style={{ backgroundColor: primaryColor, color: backgroundColor }}
        >
          <CheckboxOrderBump
            labelText={confirmationButtonText}
            onCheckedChange={onCheckChange}
            borderColor={secondaryColor}
            iconColor={secondaryColor}
            value={checked}
            radius={6}
          />
        </div>
        <p>{title}</p>
        <div
          className={styles.responsiveContainer}
          style={{ flexDirection: isMobile ? 'column' : 'row' }}
        >
          {isMobile
            ? !!mobileLink && <img className={styles.image} src={mobileLink} />
            : !!webLink && <img className={styles.image} src={webLink} />}
          <div
            className={styles.descriptionContent}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </>
  )
}
