import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useCheckoutDetails } from 'src/features/plan/checkout/hooks/useCheckoutDetails'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'

import { CheckoutFormPayload } from '../../checkout/typings/checkoutFormTypings'
import { mixpanel } from '../utils/mixpanel'

export function useAddedContactInfoMixPanel() {
  const { checkoutDetails } = useCheckoutDetails()
  const { product } = useProduct()
  const queryParams = useCheckoutParamsFromUrl()
  const { getValues } = useFormContext<CheckoutFormPayload>()

  const { src } = queryParams

  const onEvent = useCallback(() => {
    if (!product) return

    const { email, name, phone } = getValues()

    const event = {
      plan: {
        id: product.id.toString(),
        name: product.publicName,
      },
      ...queryParams,
    }

    mixpanel().register({
      email,
      name,
      phone,
    })
    mixpanel().track('AddContactInfo', event)
  }, [checkoutDetails, product, src])

  return onEvent
}
