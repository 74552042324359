import { useAtom } from 'jotai'
import { useCallback } from 'react'

import { checkoutAtoms } from '../atoms/checkoutAtoms'

export function useUpdateParamEmail() {
  const [, setIsUpdateParamEmail] = useAtom(checkoutAtoms.isUpdateParamEmail)
  const handleIsUpdateParamEmail = useCallback(async () => {
    setIsUpdateParamEmail(currentUpdateParamEmail => !currentUpdateParamEmail)
  }, [])

  return { handleIsUpdateParamEmail }
}
