import { PaymentMethods } from 'src/features/checkout/typings/legacyCheckoutTypings'
import { apiPayments } from 'src/infra/api'
import { SerializedApiError } from 'src/infra/typings/globalTypings'
import { createUnknownSerializedApiError } from 'src/utils/createUnknownSerializedApiError'

import { env } from '../../../configs/env'
import {
  LegacyCreditCardOrderPayload,
  LegacyOrderPayload,
  PaymentStatus,
} from '../typings/pagarmeRequestTypings'
import { RequestPaymentResponse } from '../typings/paymentTypings'

export interface TryToCreateOrderResponsePayload {
  id: number
  slug: string
  status: PaymentStatus
  funnelToken?: string
  boletoBarCode?: string
  boletoUrl?: string
  checkout: {
    origin: string
  }
}

async function tryToCreateOrder(
  body: LegacyCreditCardOrderPayload | LegacyOrderPayload
) {
  try {
    const data = await apiPayments
      .url(`${env.PATH_PAYMENTS_CRED_CARD}/pagarme`)
      .post(body)
      .json<TryToCreateOrderResponsePayload>()

    if (data.status === PaymentStatus.Refused) {
      // eslint-disable-next-line no-throw-literal
      throw { name: 'card-refused' }
    }

    return data as TryToCreateOrderResponsePayload
  } catch (error) {
    if (isAnCardRefusedError(error)) {
      throw createRefusedSerializedApiError()
    }

    throw createUnknownSerializedApiError()
  }
}

export function isAnCardRefusedError(error: unknown) {
  if (typeof error === 'object' && error !== null) {
    return (
      (error as { json: { status: string } }).json.status ===
      PaymentStatus.Refused
    )
  }

  return false
}

export function createRefusedSerializedApiError() {
  const serializedError: SerializedApiError = {
    isSerializedApiError: true,
    statusCode: 400,
    code: 'refused',
    message: 'Transação não permitida',
  }

  return serializedError
}

async function tryToCreateLegacytOrderOnAdaptedContract(
  payload: LegacyCreditCardOrderPayload | LegacyOrderPayload
) {
  const response = await tryToCreateOrder(payload)
  const isBillet = payload.paymentMethod === PaymentMethods.Billet

  const responseOnNewContract: RequestPaymentResponse = {
    id: response.id,
    status: 'success',
    funnelToken: response?.funnelToken,
    creditCardRedirectUrl: response.slug,
    paymentMethod: isBillet ? 'boleto' : 'credit_card',
  }

  return responseOnNewContract
}

export const legacyPaymentApi = {
  tryToCreateOrder,
  tryToCreateLegacytOrderOnAdaptedContract,
}
