import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

import { useIsLegacyCheckout } from 'src/features/plan/checkout/hooks/useIsLegacyCheckout'

import { useCheckoutParamsFromUrl } from '../../layout/hooks/useCheckoutParamsFromUrl'
import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { PaymentMethods } from '../typings/legacyCheckoutTypings'

export function useDefaultPaymentMethod() {
  const { isBilletMethodEnabled } = useCheckoutParamsFromUrl()
  const isMethodSelectionEnabled = useAtomValue(
    checkoutAtoms.isMethodSelectionEnabledAtom
  )
  const isLegacyCheckout = useIsLegacyCheckout()

  const defaultPaymentType = useMemo(() => {
    const paymentCredictCardMethod = isLegacyCheckout
      ? PaymentMethods.LegacyCreditCard
      : PaymentMethods.CreditCard

    return isBilletMethodEnabled || isMethodSelectionEnabled
      ? PaymentMethods.Billet
      : paymentCredictCardMethod
  }, [isLegacyCheckout, isBilletMethodEnabled])

  return defaultPaymentType
}
