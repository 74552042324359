import wretch from 'wretch'

import { fromAddressErrorToSerializedApiError } from 'src/utils/fromAddressErrorToSerializedApiError'

import { env } from '../../../configs/env'
import { PagarmeAddress } from '../typings/checkoutFormTypings'

export interface CardHash {
  public_key: string
  id: string
}

const pagarmeRestApi = wretch().url('https://api.pagar.me/1/')

async function getCustomerAddressByZipCode(customerZipCode: string) {
  return pagarmeRestApi
    .url(`zipcodes/${customerZipCode}`)
    .get()
    .notFound(error => {
      throw fromAddressErrorToSerializedApiError(error.json)
    })
    .json<PagarmeAddress>()
}

async function getCardHashKey() {
  return pagarmeRestApi
    .url('/transactions/card_hash_key')
    .query({ encryption_key: env.ENCRYPTION_KEY ?? '' })
    .get()
    .json<CardHash>()
}

export const pargarmeApi = {
  getCardHashKey,
  getCustomerAddressByZipCode,
}
