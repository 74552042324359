import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useEffect, useRef } from 'react'

import { getGoogleAnalyticsClientId } from 'src/features/analytics/utils/getGoogleAnalyticsClientId'
import { waitUntilGtagIsLoaded } from 'src/features/analytics/utils/waitUntilGtagIsLoaded'

import { useCheckoutParamsFromUrl } from '../../layout/hooks/useCheckoutParamsFromUrl'
import { legacyCheckoutApi } from '../apis/legacyCheckoutApi'
import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { usePlanIdFromRoute } from './usePlanIdFromRoute'

export async function useActiveCampaignLead() {
  const checkoutParams = useCheckoutParamsFromUrl()
  const planId = usePlanIdFromRoute()
  const refIsCallActiveCampaign = useRef(false)

  const { mutateAsync } = useMutation({
    mutationFn: legacyCheckoutApi.tryCreateActiveCampaignLead,
  })

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
  const customerData = useAtomValue(checkoutAtoms.customerData)

  const tryToCreateLead = async () => {
    await waitUntilGtagIsLoaded()
    const clientId = await getGoogleAnalyticsClientId()

    if (!clientId) return

    const payload = {
      name: checkoutParams.name,
      email: checkoutParams.email,
      phone: checkoutParams.phone,
      origin: checkoutParams.qp_origin ?? 'web',
      planId: planId,
      clientId,
    }

    mutateAsync(payload)
  }

  useEffect(() => {
    if (refIsCallActiveCampaign.current) return
    if (!checkoutParams.name || !checkoutParams.email || !checkoutParams.phone)
      return

    tryToCreateLead()

    refIsCallActiveCampaign.current = true
  }, [checkoutParams])

  const tryCreateLeadInNewCheckout = async () => {
    await waitUntilGtagIsLoaded()
    const clientId = await getGoogleAnalyticsClientId()

    if (!clientId) return

    const payload = {
      name: customerData.name,
      email: customerData.email,
      phone: customerData.phone,
      origin: checkoutParams.qp_origin ?? 'web',
      planId: planId,
      clientId,
    }

    mutateAsync(payload)
  }

  useEffect(() => {
    const isNotCustomerDataFilled =
      !customerData.name || !customerData.email || !customerData.phone

    if (refIsCallActiveCampaign.current) return
    if (isNotCustomerDataFilled || !isNewCheckout) return
    tryCreateLeadInNewCheckout()

    refIsCallActiveCampaign.current = true
  }, [customerData])
}
