import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { CheckoutFormPayloadOfAllTypes } from '../typings/checkoutFormTypings'
import { checkoutValidationSchema } from '../utils/checkoutValidationSchema'
import { useDefaultPaymentMethod } from './useDefaultPaymentMethod'

export function useCheckoutForm() {
  const defaultPaymentMethod = useDefaultPaymentMethod()

  return useForm<CheckoutFormPayloadOfAllTypes>({
    mode: 'onTouched',
    resolver: yupResolver(checkoutValidationSchema),
    defaultValues: { paymentMethod: defaultPaymentMethod },
  })
}
