import { sha256 } from 'js-sha256'

interface ReturnPropsFactoryFormOnPurchasePixels {
  em: string
  fn: string
  ln: string
  ph: string
}

type TfactoryFormOnPurchasePixelsProps = {
  name: string
  email: string
  phone: string
}
export const factoryFormOnPurchasePixels = ({
  email,
  name,
  phone,
}: TfactoryFormOnPurchasePixelsProps): ReturnPropsFactoryFormOnPurchasePixels => {
  const [firstName, lastName] = name.split(' ')

  const firtNameLowerCase = sha256(firstName.toLocaleLowerCase())
  const lastNameLowerCaseOrempty = lastName
    ? sha256(lastName.toLocaleLowerCase())
    : sha256('')
  const removePhoneSpecialCharactersAndSpacing = sha256(
    phone.replace(/[^\w]/gi, '')
  )
  const emailSha = sha256(email.toLocaleLowerCase())

  return {
    em: emailSha,
    fn: firtNameLowerCase,
    ln: lastNameLowerCaseOrempty,
    ph: removePhoneSpecialCharactersAndSpacing,
  }
}
