import RSA from 'node-rsa'
import qs from 'qs'

import { pargarmeApi } from 'src/features/checkout/apis/pagarmeApi'

import { CheckoutFormPayloadWithLegacyCreditCard } from '../../checkout/typings/checkoutFormTypings'

export const createPagarmeCardHash = async (
  payload: CheckoutFormPayloadWithLegacyCreditCard
) => {
  try {
    const response = await pargarmeApi.getCardHashKey()
    const cardString = toCardString(payload)
    return encryptCard(response.public_key, response.id, cardString)
  } catch (error) {
    throw error
  }
}

const encryptCard: (
  publicKey: string,
  id: string,
  cardString: string
) => string = (publicKey, id, cardString) => {
  const key = new RSA(publicKey, 'pkcs8-public')
  key.setOptions({
    encryptionScheme: 'pkcs1',
  })
  const encrypted = key?.encrypt(cardString, 'base64')
  return `${id}_${encrypted}`
}

export interface StringfiedPagarmeCardPayload {
  card_number: string
  card_holder_name: string
  card_expiration_date: string
  card_cvv: string
}
const toCardString = (card: CheckoutFormPayloadWithLegacyCreditCard) => {
  const data: StringfiedPagarmeCardPayload = {
    card_number: toOnlyNumbers(card.creditCardNumber),
    card_holder_name: card.creditCardName,
    card_expiration_date: toOnlyNumbers(`${card.month}${card.year}`),
    card_cvv: toOnlyNumbers(card.cvv),
  }
  const cardString = qs.stringify(data)
  return cardString
}

const toOnlyNumbers: (number: string) => string = number => {
  return number.replace(/[^0-9]/g, '')
}
