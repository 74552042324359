import { useAtomValue } from 'jotai'
import { useEffect, useRef } from 'react'

import { waitUntilGtagIsLoaded } from 'src/features/analytics/utils/waitUntilGtagIsLoaded'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'

import { useCheckoutParamsFromUrl } from '../../layout/hooks/useCheckoutParamsFromUrl'
import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { useTryToRequestServelessLeads } from './useTryToRequestServelessLeads'

export async function useServerlessLeads() {
  const checkoutParams = useCheckoutParamsFromUrl()
  const refIsCallActiveCampaign = useRef(false)

  const { offer } = useOffer()

  const { tryToRequestServelessLeads } = useTryToRequestServelessLeads()

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
  const customerData = useAtomValue(checkoutAtoms.customerData)

  const tryToCreateServerlessLeads = async (referrerPagePlan?: string) => {
    await waitUntilGtagIsLoaded()

    const payload = {
      fullName: checkoutParams.name,
      phone: checkoutParams.phone,
      email: checkoutParams.email,
      referrerPagePlan,
    }
    await tryToRequestServelessLeads(payload)
  }

  useEffect(() => {
    if (refIsCallActiveCampaign.current) return
    if (
      !checkoutParams.name ||
      !checkoutParams.email ||
      !checkoutParams.phone ||
      !offer
    )
      return

    tryToCreateServerlessLeads(checkoutParams.referrerPagePlan)

    refIsCallActiveCampaign.current = true
  }, [checkoutParams, offer])

  const tryToCreateServerlessLeadsInNewCheckout = async () => {
    await waitUntilGtagIsLoaded()

    const payload = {
      fullName: customerData.name,
      phone: customerData.phone,
      email: customerData.email,
    }

    await tryToRequestServelessLeads(payload)
  }

  useEffect(() => {
    const isNotCustomerDataFilled =
      !customerData.name || !customerData.email || !customerData.phone

    if (refIsCallActiveCampaign.current) return
    if (isNotCustomerDataFilled || !isNewCheckout || !offer) return

    tryToCreateServerlessLeadsInNewCheckout()

    refIsCallActiveCampaign.current = true
  }, [customerData, offer])
}
