/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

const fbqWithFallbackToNoop: any = (...args: any) => {
  const fb: any = window.fbq ?? noop
  return fb(...args)
}

export const fbq: facebook.Pixel.Event = fbqWithFallbackToNoop
