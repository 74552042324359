import styled from '@emotion/styled'
import { toast } from 'react-toastify'

interface ToastProps {
  message: string
  type: 'success' | 'error'
}

export const ToastCustom = ({ message, type }: ToastProps) => {
  const isSuccess = type === 'success'

  if (isSuccess) {
    return toast.success(message)
  }

  toast.error(
    <Container>
      <img src='/assets/errorOutlineWhite.svg' alt='icon' />
      {message}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 18px;
  }
`
