interface SerializedApiError {
  message: string
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fromAddressErrorToSerializedApiError = (error: any) => {
  const serializedError: SerializedApiError = {
    message: error.errors[0]?.message,
  }

  return serializedError
}
