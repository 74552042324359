import { useQuery } from '@tanstack/react-query'
import { useFormContext } from 'react-hook-form'

import { zipCodeSizeWithoutMask } from 'src/utils/address'

import { pargarmeApi } from '../apis/pagarmeApi'
import { Address } from '../typings/checkoutFormTypings'
interface CustomerAddressError {
  message: string
}

export function useCustomerAddress() {
  const { watch } = useFormContext<Address>()

  const zipCode = watch('zipCode')

  const { isLoading, error, data, isError } = useQuery({
    queryKey: ['customer-address', zipCode],
    queryFn: () => {
      return pargarmeApi.getCustomerAddressByZipCode(String(zipCode))
    },
    enabled: !!zipCode && zipCodeSizeWithoutMask(zipCode) >= 8,
  })

  return {
    isLoading,
    isError,
    customerAddressError: error as CustomerAddressError,
    customerAddress: data,
  }
}
