import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { Control, useWatch } from 'react-hook-form'

import { useIsLegacyCheckout } from 'src/features/plan/checkout/hooks/useIsLegacyCheckout'
import { isAmericanExpressOrDiscover } from 'src/utils/creditCard'

import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { CheckoutFormPayloadOfAllTypes } from '../typings/checkoutFormTypings'
import { PaymentMethods } from '../typings/legacyCheckoutTypings'

export function useWatchInstallmentsEnabled(
  control: Control<CheckoutFormPayloadOfAllTypes>
) {
  const isLegacyCheckout = useIsLegacyCheckout()
  const [
    isPaymentWithInstallmentsEnabled,
    setIsPaymentWithInstallmentsEnabled,
  ] = useAtom(checkoutAtoms.isPaymentWithInstallmentsEnabledAtom)

  const [creditCardNumber, paymentMethod] = useWatch({
    name: ['creditCardNumber', 'paymentMethod'],
    control,
  })
  const isAmrOrDis = isAmericanExpressOrDiscover(creditCardNumber)
  useEffect(() => {
    const isBillet = paymentMethod === PaymentMethods.Billet
    const isPix = paymentMethod === PaymentMethods.Pix

    const isEnabledForCard = isLegacyCheckout ? !isAmrOrDis : true

    const isEnabled = isBillet || isPix ? false : isEnabledForCard
    setIsPaymentWithInstallmentsEnabled(isEnabled)
  }, [isAmrOrDis, isLegacyCheckout, paymentMethod])

  return isPaymentWithInstallmentsEnabled
}
