import { Box, Flex, Grid } from '@queimadiaria/ui'
import { useAtomValue } from 'jotai'
import { FC } from 'react'

import { FetchAvailableCheckoutResponse } from 'src/features/plan/checkout/apis/checkoutApi'

import { useCheckoutParamsFromUrl } from '../../layout/hooks/useCheckoutParamsFromUrl'
import { useImages } from '../../layout/hooks/useImages'
import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { Banner } from '../components/Banner'
import { CheckoutFormContainer } from '../components/CheckoutForm'

type TCheckout = {
  initAvailableCheckout?: FetchAvailableCheckoutResponse
}

export const Checkout: FC<TCheckout> = ({ initAvailableCheckout }) => {
  const { banner, sideBanner, bannerMobile, footerBadges } = useImages(
    initAvailableCheckout?.checkout
  )
  const { isNoBanners, refundMessage } = useCheckoutParamsFromUrl()

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)

  if (!isNewCheckout) {
    return (
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          boxSizing: 'border-box',
          maxWidth: '100%',
        }}
      >
        <Box
          sx={{
            width: ['90%', '70%', '90%', '904px'],
          }}
        >
          {!isNoBanners && <Banner type='desktop-on-top' banner={banner} />}
          {!isNoBanners && (
            <Banner type='mobile-on-top' banner={bannerMobile} />
          )}
          <Grid
            columns={[
              '100%',
              '100%',
              '100%',
              isNoBanners ? '100%' : '1fr 230px',
            ]}
            gap='32px'
          >
            <CheckoutFormContainer />
            {!isNoBanners && (
              <Banner type='desktop-on-side' banner={sideBanner} />
            )}
          </Grid>
        </Box>
        {!isNoBanners && !refundMessage && (
          <Box
            sx={{
              display: ['block', 'block', 'none'],
              my: 'large',
              width: ['90%', '70%', 'auto', 'auto'],
            }}
          >
            <Banner type='mobile-on-footer' banner={footerBadges} />
          </Box>
        )}
      </Flex>
    )
  }

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box',
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          width: ['100%', '442px'],
          marginBlockEnd: '42px',
          marginBlockStart: '42px',
        }}
      >
        <Grid
          sx={{
            background: '#FFFFFF',
            border: ['none', '1px solid #EEEEEE'],
            boxShadow: [
              'none',
              '0px 8.6px 12.9px -2.15px rgba(0, 0, 0, 0.1), 0px 4.3px 8.6px -2.15px rgba(0, 0, 0, 0.06)',
            ],
            borderRadius: '4px',
          }}
          columns={'100%'}
          gap='32px'
        >
          <CheckoutFormContainer />
        </Grid>
      </Box>
    </Flex>
  )
}
