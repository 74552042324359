import { env } from 'src/configs/env'
import { FetchAvailableCheckoutResponse } from 'src/features/plan/checkout/apis/checkoutApi'
import { OfferSubscriptionPeriod } from 'src/features/plan/offer/typings/offerTypings'
import { Product } from 'src/features/plan/product/typings/productTypings'
import { api } from 'src/infra/api'
import { createListOfInstallmentsItemsFromNow } from 'src/utils/collectionUtils'

import { Plan } from '../typings/planTypings'

async function getPlanById(planId: string) {
  return api.url(`/checkout/plan/${planId}`).get().json<Plan>()
}

async function getPlayByIdOnAdaptedContract(planId: string) {
  const plan = await getPlanById(planId)
  const payload: Omit<FetchAvailableCheckoutResponse, 'product'> & {
    product: Omit<Omit<Product, 'isDirectFallback'>, 'paymentMethods'>
  } = {
    product: {
      id: plan?.id,
      alias: plan?.alias,
      publicName: plan?.name,
      fullOldPlanId: plan?.id,
      subscriptionType: plan?.subscriptionType
        ? plan?.subscriptionType
        : 'yearly',
    },
    offer: {
      id: plan?.id,
      name: plan?.name,
      fullPrice: plan?.price,
      installments: 12,
      installmentsItems: createListOfInstallmentsItemsFromNow(12, plan?.price),
      subscriptionPeriod: OfferSubscriptionPeriod.Yearly,
      productSellGroupId: 'legacy-psg-id-placeholder',
      blockedInstallments: plan?.disclaimer ? true : false,
      offerFormat: plan?.disclaimer
        ? {
            description: plan?.disclaimer || '',
          }
        : null,
    },
    checkout: {
      id: plan?.id,
      bannerMobile: plan?.bannerMobile,
      footerBadges: plan?.footerBadges,
      bannerSideWeb: plan?.sideBanner,
      bannerWeb: plan?.metadata?.newBanner || plan?.banner,
      logo: plan?.logo,
      offerId: plan?.id,
      privacyPolicyRef: env.FALLBACK_PRIVACY_POLICE ?? '',
      termsRef: env.FALLBACK_TERMS_OF_USE ?? '',
      facebookPixels: plan?.metadata?.checkout?.pixel?.ids,
      googleAnalyticsIds: first(plan?.metadata?.checkout?.analytics),
      origin: plan?.metadata?.checkout?.origin,
    },
  }

  return payload
}

function first<T>(values?: T[]) {
  if (!values) return
  return values[0]
}

interface TryCreateActiveCampaignLeadPayload {
  name?: string
  email?: string
  phone?: string
  origin: string
  planId?: string
  clientId?: string
}

async function tryCreateActiveCampaignLead(
  values: TryCreateActiveCampaignLeadPayload
) {
  try {
    await api.url('/checkout/active-campaign').post(values)
  } catch (error) {}
}

export const legacyCheckoutApi = {
  getPlanById,
  getPlayByIdOnAdaptedContract,
  tryCreateActiveCampaignLead,
}
