import { BrazilianPhone } from '../typings/paymentTypings'

export function parseBrazilianPhone(rawPhone: string): BrazilianPhone {
  const cleanedPhone = rawPhone.replace(/\D/g, '')

  return {
    ddd: cleanedPhone.slice(0, 2),
    phone: cleanedPhone.slice(2),
  }
}
