import { Box } from '@queimadiaria/ui'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { CheckoutFormPayloadOfAllTypes } from 'src/features/checkout/typings/checkoutFormTypings'
import { Offer } from 'src/features/plan/offer/typings/offerTypings'

import { useOrderBumpCheckoutStore } from '../../hooks/useOrderBumpCheckoutStore'
import type { TOrderBump } from '../../types/orderBump'
import { TemplateA } from './TemplateA/TemplateA'
import { TemplateB } from './TemplateB/TemplateB'

export type TOrderBumpCheckoutProps = {
  offer: Offer
  markBump: boolean
}

export type TOrderBumpCheckoutTemplateProps = {
  orderBumpData: TOrderBump
  onCheckChange: (value: boolean) => void
  checked: boolean
}

export function OrderBumpCheckout({
  offer,
  markBump,
}: TOrderBumpCheckoutProps) {
  const { control } = useFormContext<CheckoutFormPayloadOfAllTypes>()
  const { setIsSelectedOrderBump } = useOrderBumpCheckoutStore()

  const Component = useMemo(() => {
    if (!offer.orderBump) return null

    switch (offer.orderBump.template) {
      case 'tempA':
        return TemplateA
      case 'tempB':
        return TemplateB
      default:
        return null
    }
  }, [offer])

  if (!Component || !offer) return <></>

  return (
    <Controller
      name='orderBump'
      control={control}
      defaultValue={markBump ? offer.orderBump! : null}
      render={({ field: { onChange, value } }) => (
        <Box>
          <Component
            orderBumpData={offer.orderBump!}
            checked={!!value}
            onCheckChange={changedValue => {
              onChange(changedValue ? offer.orderBump! : null)
              setIsSelectedOrderBump(changedValue)
            }}
          />
        </Box>
      )}
    />
  )
}
