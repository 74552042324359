import { Box, Flex, Text } from '@queimadiaria/ui'

import { useAnotherFormOfPayment } from '../hooks/useAnotherFormOfPayment'

export const AnotherFormOfPayment: React.FC = () => {
  const { handlerUseAnotherFormOfPayment } = useAnotherFormOfPayment()

  return (
    <Box
      sx={{
        paddingInline: '32px',
        marginBlockEnd: '24px',
        marginBlockStart: '8px',
      }}
    >
      <Text variant='subheadline-2' css={{ marginBlockEnd: 16 }}>
        Outras formas de pagamento
      </Text>
      <Flex
        sx={{ cursor: 'pointer' }}
        onClick={() => handlerUseAnotherFormOfPayment()}
      >
        <img src='/assets/arrowLeftBlue.svg' alt='Seta a esquerda' />
        <Text variant='body-1' color='#0061F9' css={{ marginInlineStart: 8 }}>
          Voltar e usar outro cartão
        </Text>
      </Flex>
    </Box>
  )
}
