import { isValidCPF, isValidPhone } from '@brazilian-utils/brazilian-utils'
import cardValidator from 'card-validator'
import * as yup from 'yup'

import { isValidState } from 'src/utils/address'
import { validateExpirationDate } from 'src/utils/creditCard'

import { PaymentMethods } from '../typings/legacyCheckoutTypings'

export const checkoutValidationSchema = yup.object({
  name: yup.string().required('Nome é obrigatório'),
  email: yup.string().email('Email é inválido').required('Email é obrigatório'),
  emailConfirmation: yup
    .string()
    .email('Email é inválido')
    .test(
      'is-equals',
      'Seus emails não são iguais',
      (emailConfirmation, email) => {
        /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
        if (location.href.includes('emailConfirmation'))
          return emailConfirmation === email.parent.email
        return true
      }
    ),
  document: yup
    .string()
    .required('CPF é obrigatório')
    .test('is-cpf', 'CPF é inválido', value => {
      return isValidCPF(value ?? '')
    }),
  phone: yup
    .string()
    .required('Telefone é obrigatório')
    .test('is-phone', 'Telefone é inválido', value => {
      return isValidPhone(value ?? '')
    }),

  paymentMethod: yup.string().oneOf(Object.values(PaymentMethods)).required(),
  card: yup.object().when('paymentMethod', {
    is: PaymentMethods.CreditCard,
    then: yup
      .object({
        encryptedCardNumber: yup.string().required(),
        encryptedExpiryMonth: yup.string().required(),
        encryptedExpiryYear: yup.string().required(),
        encryptedSecurityCode: yup.string().required(),
        holderName: yup.string().required('Nome no cartão é obrigatório'),
      })
      .required(),
  }),
  nupay: yup.object().when('paymentMethod', {
    is: PaymentMethods.Nupay,
    then: yup
      .object({
        fundingSource: yup.string().required(),
        installment: yup
          .number()
          .test(
            'installment-nupay',
            'Parcelamento obrigatório',
            (value, context) => {
              return context.parent.nupay?.fundingSource === 'debit' || !!value
            }
          ),
        isFallback: yup.boolean().required(),
        authReqId: yup.string().required(),
        isSendedAuthorization: yup.boolean().required(),
      })
      .required(),
  }),
  creditCardNumber: yup.string().when('paymentMethod', {
    is: PaymentMethods.LegacyCreditCard,
    then: yup
      .string()
      .required('Número do cartão de crédito é obrigatório')
      .test(
        'is-valid-credit-card',
        'Número do cartão de crédito inválido',
        value => {
          return cardValidator.number(value).isValid
        }
      ),
    otherwise: yup.string().optional(),
  }),
  creditCardName: yup.string().when('paymentMethod', {
    is: PaymentMethods.LegacyCreditCard,
    then: yup.string().required('Nome do cartão de crédito é obrigatório'),
    otherwise: yup.string().optional(),
  }),
  cardExpiration: yup.string().when('paymentMethod', {
    is: PaymentMethods.LegacyCreditCard,
    then: yup
      .string()
      .required('Validade é obrigatória')
      .test('is-valid-expiration', 'Data inválida', value =>
        validateExpirationDate(value ?? '')
      ),
    otherwise: yup.string().optional(),
  }),
  month: yup.string().when('paymentMethod', {
    is: PaymentMethods.LegacyCreditCard,
    then: yup.string().required('Mês é obrigatório'),
    otherwise: yup.string().optional(),
  }),
  year: yup.string().when('paymentMethod', {
    is: PaymentMethods.LegacyCreditCard,
    then: yup.string().required('Ano é obrigatório'),
    otherwise: yup.string().optional(),
  }),
  cvv: yup.string().when('paymentMethod', {
    is: PaymentMethods.LegacyCreditCard,
    then: yup
      .string()
      .required('CVV é obrigatório')
      .min(3, 'O Código de segurança deve conter pelo menos 3 digitos'),
    otherwise: yup.string().optional(),
  }),
  zipCode: yup.string().when('paymentMethod', {
    is: PaymentMethods.Billet,
    then: yup
      .string()
      .required('CEP é obrigatório')
      .min(8, 'O CEP deve conter pelo menos 8 digitos'),
    otherwise: yup.string().optional(),
  }),
  street: yup.string().when('paymentMethod', {
    is: PaymentMethods.Billet,
    then: yup.string().required('Rua é obrigatório'),
    otherwise: yup.string().optional(),
  }),
  noHasNumber: yup.number().when('paymentMethod', {
    is: PaymentMethods.Billet,
    otherwise: yup.number().optional(),
  }),
  number: yup.string().when(['paymentMethod', 'noHasNumber'], {
    is: (paymentMethod: PaymentMethods, noHasNumber: number) => {
      return paymentMethod === PaymentMethods.Billet && noHasNumber === 0
    },
    then: yup.string().required('Número é obrigatório'),
    otherwise: yup.string().optional(),
  }),
  district: yup.string().when('paymentMethod', {
    is: PaymentMethods.Billet,
    then: yup.string().required('Bairro é obrigatório'),
    otherwise: yup.string().optional(),
  }),
  city: yup.string().when('paymentMethod', {
    is: PaymentMethods.Billet,
    then: yup.string().required('Cidade é obrigatório'),
    otherwise: yup.string().optional(),
  }),
  state: yup.string().when('paymentMethod', {
    is: PaymentMethods.Billet,
    then: yup
      .string()
      .required('Estado é obrigatório')
      .test('is-valid-state', 'Estado não encontrado', stateCode => {
        if (stateCode) return isValidState(stateCode)
        return false
      }),
    otherwise: yup.string().optional(),
  }),
  recaptchaToken: yup
    .string()
    .when('paymentMethod', (paymentMethod, schema) => {
      const paymentMethods = [
        PaymentMethods.CreditCard,
        PaymentMethods.LegacyCreditCard,
      ]

      if (paymentMethods.includes(paymentMethod)) {
        return schema.required('ReCAPTCHA é obrigatório')
      }

      return schema
    }),
})
