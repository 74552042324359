import styled from '@emotion/styled'
import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  LegacyTextInput,
} from '@queimadiaria/ui'
import { useAtomValue } from 'jotai'
import { ChangeEvent, FC, memo, useEffect } from 'react'
import { Control, Controller, useFormContext } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { zipCodeUnMask } from 'src/utils/address'

import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { useCustomerAddress } from '../hooks/useCustomerAddress'
import { Address } from '../typings/checkoutFormTypings'

export const AddressFormContainer: FC = () => {
  const { control, setValue, setError, clearErrors } = useFormContext<Address>()
  const { customerAddress, customerAddressError, isError } =
    useCustomerAddress()

  useEffect(() => {
    if (isError) setError('zipCode', { message: customerAddressError?.message })
    else {
      setValue('street', customerAddress?.street ?? '')
      setValue('district', customerAddress?.neighborhood ?? '')
      setValue('city', customerAddress?.city ?? '')
      setValue('state', customerAddress?.state ?? '')
      clearErrors(['street', 'district', 'city', 'state'])
    }
  }, [customerAddress, isError, customerAddressError])

  return <AddressForm control={control} />
}

interface AddressFormProps {
  control: Control<Address>
  isNewCheckout?: boolean
}

const AddressForm: FC<AddressFormProps> = memo(({ control }) => {
  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)

  if (!isNewCheckout) {
    return (
      <div>
        <Flex
          sx={{
            flexDirection: 'column',
            margin: '0 0 24px 0',
            width: ['100%', '100%', '75%', '75%'],
          }}
        >
          <Box
            sx={{
              flex: '1 0',
              maxWidth: '200px',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            <Controller
              name='zipCode'
              control={control}
              render={({
                field: { onChange, onBlur, value, name },
                fieldState: { invalid, error },
              }) => (
                <FormControl mt='medium' hasError={invalid}>
                  <FormLabel htmlFor={name}>CEP</FormLabel>
                  <NumberFormat
                    css={{ fontSize: '14px' }}
                    id={name}
                    name={name}
                    value={value}
                    type='tel'
                    format='#####-###'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const unmask = zipCodeUnMask(e.target.value)
                      onChange(unmask)
                    }}
                    onBlur={onBlur}
                    placeholder='Digite seu CEP'
                    customInput={LegacyTextInput}
                  />
                  <FormHelperText errorMessage={error?.message} />
                </FormControl>
              )}
            />
          </Box>
          <Controller
            defaultValue={''}
            name='street'
            control={control}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <FormControl mt='medium' hasError={invalid}>
                <FormLabel htmlFor={name}>Rua</FormLabel>
                <LegacyTextInput
                  css={{ fontSize: '14px' }}
                  id={name}
                  name={name}
                  type='text'
                  inputMode='text'
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  placeholder='Digite o nome da sua rua/avenida'
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )}
          />
          <AddressNumberAndComplement control={control} />
          <Controller
            defaultValue={''}
            name='district'
            control={control}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <FormControl mt='medium' hasError={invalid}>
                <FormLabel htmlFor={name}>Bairro</FormLabel>
                <LegacyTextInput
                  css={{ fontSize: '14px' }}
                  id={name}
                  name={name}
                  type='text'
                  inputMode='text'
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  placeholder='Digite o nome do seu bairro'
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )}
          />

          <Flex
            sx={{
              display: 'flex',
              flex: '1 0',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <Controller
              defaultValue={''}
              name='city'
              control={control}
              render={({
                field: { onChange, onBlur, value, name },
                fieldState: { invalid, error },
              }) => (
                <FormControl mt='medium' hasError={invalid}>
                  <FormLabel htmlFor={name}>Cidade</FormLabel>
                  <LegacyTextInput
                    css={{ fontSize: '14px' }}
                    id={name}
                    name={name}
                    type='text'
                    inputMode='text'
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    placeholder='Digite o nome da sua cidade'
                  />
                  <FormHelperText errorMessage={error?.message} />
                </FormControl>
              )}
            />
            <Box
              sx={{
                width: ['50%', '50%', '30%', '30%'],
              }}
            >
              <Controller
                defaultValue={''}
                name='state'
                control={control}
                render={({
                  field: { onChange, onBlur, value, name },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl mt='medium' hasError={invalid}>
                    <FormLabel htmlFor={name}>Estado</FormLabel>
                    <LegacyTextInput
                      css={{ fontSize: '14px' }}
                      id={name}
                      name={name}
                      type='text'
                      inputMode='text'
                      maxLength={2}
                      value={value}
                      onChange={e => {
                        onChange(e.target.value.toUpperCase())
                      }}
                      onBlur={onBlur}
                    />
                    <FormHelperText errorMessage={error?.message} />
                  </FormControl>
                )}
              />
            </Box>
          </Flex>
        </Flex>
      </div>
    )
  }

  return (
    <div>
      <Flex
        sx={{
          flexDirection: 'column',
          margin: '0 0 24px 0',
          width: '100%',
        }}
      >
        <Box
          sx={{
            flex: '1 0',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          <Controller
            name='zipCode'
            control={control}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <FormControl mt='medium' hasError={invalid}>
                <NewFormLabel htmlFor={name}>CEP</NewFormLabel>
                <NumberFormat
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                  }}
                  id={name}
                  name={name}
                  value={value}
                  type='tel'
                  format='#####-###'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const unmask = zipCodeUnMask(e.target.value)
                    onChange(unmask)
                  }}
                  onBlur={onBlur}
                  placeholder='Digite seu CEP'
                  customInput={LegacyTextInput}
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )}
          />
        </Box>
        <Controller
          defaultValue={''}
          name='street'
          control={control}
          render={({
            field: { onChange, onBlur, value, name },
            fieldState: { invalid, error },
          }) => (
            <FormControl mt='medium' hasError={invalid}>
              <NewFormLabel htmlFor={name}>Rua</NewFormLabel>
              <LegacyTextInput
                style={{
                  background: '#FFFFFF',
                  boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                }}
                id={name}
                name={name}
                type='text'
                inputMode='text'
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={onBlur}
                placeholder='Digite o nome da sua rua/avenida'
              />
              <FormHelperText errorMessage={error?.message} />
            </FormControl>
          )}
        />
        <AddressNumberAndComplement isNewCheckout control={control} />
        <Controller
          defaultValue={''}
          name='district'
          control={control}
          render={({
            field: { onChange, onBlur, value, name },
            fieldState: { invalid, error },
          }) => (
            <FormControl mt='medium' hasError={invalid}>
              <NewFormLabel htmlFor={name}>Bairro</NewFormLabel>
              <LegacyTextInput
                style={{
                  background: '#FFFFFF',
                  boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                }}
                id={name}
                name={name}
                type='text'
                inputMode='text'
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={onBlur}
                placeholder='Digite o nome do seu bairro'
              />
              <FormHelperText errorMessage={error?.message} />
            </FormControl>
          )}
        />

        <Flex
          sx={{
            display: 'flex',
            flex: '1 0',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          <Controller
            defaultValue={''}
            name='city'
            control={control}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <FormControl mt='medium' hasError={invalid}>
                <NewFormLabel htmlFor={name}>Cidade</NewFormLabel>
                <LegacyTextInput
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                  }}
                  id={name}
                  name={name}
                  type='text'
                  inputMode='text'
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  placeholder='Digite o nome da sua cidade'
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )}
          />
          <Box
            sx={{
              width: ['50%', '50%', '30%', '30%'],
            }}
          >
            <Controller
              defaultValue={''}
              name='state'
              control={control}
              render={({
                field: { onChange, onBlur, value, name },
                fieldState: { invalid, error },
              }) => (
                <FormControl mt='medium' hasError={invalid}>
                  <NewFormLabel htmlFor={name}>Estado</NewFormLabel>
                  <LegacyTextInput
                    style={{
                      background: '#FFFFFF',
                      boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                      borderRadius: '4px',
                    }}
                    id={name}
                    name={name}
                    type='text'
                    inputMode='text'
                    maxLength={2}
                    value={value}
                    placeholder='UF'
                    onChange={e => {
                      onChange(e.target.value.toUpperCase())
                    }}
                    onBlur={onBlur}
                  />
                  <FormHelperText errorMessage={error?.message} />
                </FormControl>
              )}
            />
          </Box>
        </Flex>
      </Flex>
    </div>
  )
})

const AddressNumberAndComplement: FC<AddressFormProps> = memo(
  ({ control, isNewCheckout }) => {
    const { setValue, watch, clearErrors } = useFormContext<Address>()

    const noHasNumber = watch('noHasNumber') === 1

    useEffect(() => {
      if (noHasNumber) {
        setValue('number', '')
        clearErrors('number')
      }
    }, [noHasNumber])

    if (!isNewCheckout) {
      return (
        <Flex
          sx={{
            flex: '1 0',
            justifyContent: 'space-between',
            gap: '20px',
            flexDirection: ['column', 'row', 'row', 'row'],
          }}
        >
          <Flex
            sx={{
              width: ['100%', '40%', '30%', '30%'],
              flexDirection: ['row', 'column', 'column', 'column'],
            }}
          >
            <Controller
              name='number'
              control={control}
              render={({
                field: { onChange, onBlur, value, name },
                fieldState: { invalid, error },
              }) => (
                <FormControl mt='medium' hasError={invalid}>
                  <FormLabel htmlFor={name}>Número</FormLabel>
                  <NumberFormat
                    css={{
                      fontSize: '14px',
                      backgroundColor: noHasNumber ? '#F5F5F5' : 'none',
                    }}
                    id={name}
                    name={name}
                    value={value}
                    type='tel'
                    disabled={noHasNumber}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChange(e.target.value)
                    }
                    onBlur={onBlur}
                    customInput={LegacyTextInput}
                  />
                  <FormHelperText errorMessage={error?.message} />
                </FormControl>
              )}
            />
            <Controller
              name='noHasNumber'
              control={control}
              defaultValue={0}
              render={({ field: { onChange, value, name } }) => (
                <Flex
                  sx={{
                    alignItems: 'center',
                    gap: '6px',
                    marginLeft: '6px',
                    width: '100%',
                    marginTop: ['32px', '0px', '0px', '0px'],
                  }}
                >
                  <input
                    type='checkbox'
                    id={name}
                    name={name}
                    checked={value === 1}
                    value={value}
                    onChange={e => {
                      onChange(Number(e.target.value) === 1 ? 0 : 1)
                    }}
                  />
                  <FormLabel htmlFor={name}>Sem número</FormLabel>
                </Flex>
              )}
            />
          </Flex>
          <Controller
            name='complement'
            control={control}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <FormControl mt='medium' hasError={invalid}>
                <FormLabel htmlFor={name}>Complemento (opcional)</FormLabel>
                <LegacyTextInput
                  css={{ fontSize: '14px' }}
                  id={name}
                  name={name}
                  type='text'
                  inputMode='text'
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )}
          />
        </Flex>
      )
    }

    return (
      <Flex
        sx={{
          flex: '1 0',
          justifyContent: 'space-between',
          gap: '16px',
          flexDirection: ['column', 'row', 'row', 'row'],
        }}
      >
        <Flex
          sx={{
            width: '100%',
            flexDirection: ['row', 'column', 'column', 'column'],
          }}
        >
          <Controller
            name='number'
            control={control}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <FormControl mt='medium' hasError={invalid}>
                <NewFormLabel htmlFor={name}>Número</NewFormLabel>
                <NumberFormat
                  style={{
                    background: noHasNumber ? 'none' : '#FFFFFF',
                    boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                  }}
                  // css={{
                  //   backgroundColor: noHasNumber ? '#F5F5F5' : 'none',
                  // }}
                  id={name}
                  name={name}
                  value={value}
                  type='tel'
                  disabled={noHasNumber}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChange(e.target.value)
                  }
                  onBlur={onBlur}
                  customInput={LegacyTextInput}
                  placeholder='Número'
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )}
          />
          <Controller
            name='noHasNumber'
            control={control}
            defaultValue={0}
            render={({ field: { onChange, value, name } }) => (
              <Flex
                sx={{
                  alignItems: 'center',
                  gap: '6px',
                  marginLeft: '6px',
                  width: '100%',
                  marginTop: ['32px', '0px', '0px', '0px'],
                }}
              >
                <input
                  type='checkbox'
                  id={name}
                  name={name}
                  checked={value === 1}
                  value={value}
                  onChange={e => {
                    onChange(Number(e.target.value) === 1 ? 0 : 1)
                  }}
                />
                <NewFormLabel isMarginBlockNone htmlFor={name}>
                  Sem número
                </NewFormLabel>
              </Flex>
            )}
          />
        </Flex>
        <Controller
          name='complement'
          control={control}
          render={({
            field: { onChange, onBlur, value, name },
            fieldState: { invalid, error },
          }) => (
            <FormControl mt='medium' hasError={invalid}>
              <NewFormLabel htmlFor={name}>Complemento (opcional)</NewFormLabel>
              <LegacyTextInput
                style={{
                  background: '#FFFFFF',
                  boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                }}
                id={name}
                name={name}
                type='text'
                inputMode='text'
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={onBlur}
                placeholder='Apto, lote, prédio...'
              />
              <FormHelperText errorMessage={error?.message} />
            </FormControl>
          )}
        />
      </Flex>
    )
  }
)

interface NewFormLabelProps {
  isMarginBlockNone?: boolean
}

const NewFormLabel = styled(FormLabel)<NewFormLabelProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  letter-spacing: -0.02em;

  color: #616161;
  margin-block-end: ${props => (props.isMarginBlockNone ? '0px' : '6px')};
`
