import { getGoogleAnalyticsClientId } from 'src/features/analytics/utils/getGoogleAnalyticsClientId'
import {
  CheckoutFormPayload,
  CheckoutFormPayloadWithPix,
} from 'src/features/checkout/typings/checkoutFormTypings'
import { QueryParams } from 'src/features/checkout/typings/queryParamsType'
import { isCheckoutFormPayloadWithPix } from 'src/features/checkout/utils/checkoutFormTypeGuards'
import { Offer } from 'src/features/plan/offer/typings/offerTypings'
import { offerPaymentPayload } from 'src/modules/payment/methods/utils/buildOfferPaymentPayload'
import { getOnlyNumbers } from 'src/utils/string'

import {
  TryRequestPaymentPixPayload,
  TryRequestPixPaymentVerification,
} from '../typings/paymentTypings'
import { PixPaymentDetailsProps } from '../typings/useTryToCreateOrderPixTypings'
import { parseBrazilianPhone } from './parseBrazilianPhone'

interface BasePixOrderParams {
  src: string | null
  formData: CheckoutFormPayloadWithPix
  origin: string
  productSellGroupId: number | null
  offerId: number | null
  isNewCheckout: boolean
  isDirectCheckout: boolean
  sessionId: string
  recoveryId?: string
  offer?: Offer
}

const toPixOrder = async (
  queryParams: QueryParams,
  dataForm: CheckoutFormPayload,
  isNewCheckout: boolean,
  isDirectCheckout: boolean,
  sessionId: string,
  offer?: Offer
): Promise<TryRequestPaymentPixPayload> => {
  if (!isCheckoutFormPayloadWithPix(dataForm))
    throw new Error('Invalid payload')

  const offerId = offer ? parseInt(offer.id.toString()) : null

  const { src, qp_origin, recoveryId } = queryParams
  const checkoutParams = {
    src: src ?? null,
    formData: dataForm,
    origin: qp_origin ?? 'qp-desktop',
    productSellGroupId: offer
      ? parseInt(offer.productSellGroupId.toString())
      : null,
    offerId,
    isNewCheckout,
    isDirectCheckout,
    sessionId,
    recoveryId,
    offer,
  }

  return toPixOrderFactory(checkoutParams)
}

const toPixOrderFactory = async ({
  src,
  formData,
  origin,
  productSellGroupId,
  isNewCheckout,
  offerId,
  isDirectCheckout,
  sessionId,
  recoveryId,
  offer,
}: BasePixOrderParams): Promise<TryRequestPaymentPixPayload> => {
  const offerPayload = offerPaymentPayload(offer, formData)
  const body: TryRequestPaymentPixPayload = {
    name: formData.name,
    email: formData.email,
    card: null,
    productSellGroupId,
    installments: 1,
    document: getOnlyNumbers(formData.document),
    phone: parseBrazilianPhone(formData.phone),
    customerId: null,
    origin,
    partnerId: 1,
    src,
    paymentMethod: 'pix',
    gaClientId: await getGoogleAnalyticsClientId(),
    isNewCheckout,
    isDirectCheckout,
    sessionId,
    recoveryId,
    fullOrigin: window.location.href,
    offerId,
    ...offerPayload,
  }

  return body
}

const bodyFactoryForPixPaymentVerification = async (
  formPayload: CheckoutFormPayload,
  pixData: PixPaymentDetailsProps,
  isNewCheckout: boolean,
  isDirectCheckout: boolean,
  offer?: Offer
): Promise<TryRequestPixPaymentVerification> => {
  const body = {
    productSellGroupId: offer
      ? parseInt(offer.productSellGroupId.toString())
      : null,
    document: getOnlyNumbers(formPayload.document),
    qrCode: pixData.qrCode,
    isNewCheckout,
    isDirectCheckout,
  }

  return body
}

export const requestPixBodyFactories = {
  toPixOrderFactory: toPixOrder,
  toVerificationPixPayment: bodyFactoryForPixPaymentVerification,
}
