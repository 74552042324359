import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { useInterval } from 'react-use'

import { PixPaymentAtoms } from '../atoms/PixPaymentAtoms'
import { ReturnPropsUseCopyFieldFromPix } from '../typings/useCopyFieldFromPrixTypings'

export function useCopyFieldFromPix(): ReturnPropsUseCopyFieldFromPix {
  const [isCopyQrCode, setIsCopyQrCode] = useAtom(PixPaymentAtoms.isCopyQrCode)

  const handleCopyField = useCallback((qrCode: string) => {
    navigator.clipboard.writeText(qrCode)
    setIsCopyQrCode(true)
  }, [])

  const handleSetIsCopyQrCodeWithFalse = useCallback(() => {
    setIsCopyQrCode(false)
  }, [])

  useInterval(handleSetIsCopyQrCodeWithFalse, isCopyQrCode ? 2000 : null)

  return { isCopyQrCode, handleCopyField }
}
