import * as Sentry from '@sentry/nextjs'
import { useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai'

import { useTryToCaptureDataToMymetric } from 'src/features/analytics/hooks/useTryToCaptureDataToMymetric'
import { getGoogleAnalyticsClientId } from 'src/features/analytics/utils/getGoogleAnalyticsClientId'
import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'
import { ToastCustom } from 'src/features/checkout/components/Toast'
import { CheckoutFormPayload } from 'src/features/checkout/typings/checkoutFormTypings'
import {
  isCheckoutFormPayloadWithCreditCard,
  isCheckoutFormPayloadWithLegacyCreditCard,
} from 'src/features/checkout/utils/checkoutFormTypeGuards'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useCheckoutDetails } from 'src/features/plan/checkout/hooks/useCheckoutDetails'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'
import { SerializedApiError } from 'src/infra/typings/globalTypings'
import { offerPaymentPayload } from 'src/modules/payment/methods/utils/buildOfferPaymentPayload'
import { isSerializedApiError } from 'src/utils/isSerializedApiError'
import { getOnlyNumbers } from 'src/utils/string'

import { legacyPaymentApi } from '../apis/legacyPaymentApi'
import { paymentsApi } from '../apis/paymentApi'
import { paymentAtoms } from '../atoms/paymentAtoms'
import { TryRequestPaymentPayload } from '../typings/paymentTypings'
import { addMarketingParamsToRedirectUrl } from '../utils/addMarketingParamsToRedirectUrl'
import { checkIsSelfIndication } from '../utils/checkIsSelfIndication'
import { pagarmeRequestBodyFactories } from '../utils/pagarmeRequestBodyFactories'
import { parseBrazilianPhone } from '../utils/parseBrazilianPhone'

export function useTryToRequestPayment(
  onPurchaseSuccess?: (payload: CheckoutFormPayload) => void
) {
  const queryParams = useCheckoutParamsFromUrl()

  const [, setIsCreatingOrder] = useAtom(paymentAtoms.isCreatingOrder)
  const [, isRedirectingAfterSuccess] = useAtom(
    paymentAtoms.isRedirectingAfterSuccess
  )

  const [, setHasErrorCreatingOrder] = useAtom(
    paymentAtoms.hasErrorCreatingOrder
  )

  const [, setCreatingOrderErrorDetails] = useAtom(
    paymentAtoms.creatingOrderErrorDetails
  )

  const [, setPaymentCheckoutToFallback] = useAtom(
    checkoutAtoms.paymentCheckoutInFallback
  )

  const { qp_origin, recoveryId } = useCheckoutParamsFromUrl()
  const { offer } = useOffer()
  const { product } = useProduct()
  const { checkoutDetails } = useCheckoutDetails()

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
  const isDirectCheckout = useAtomValue(checkoutAtoms.isDirectCheckout)
  const recaptchaRef = useAtomValue(checkoutAtoms.recaptchaRef)
  const sessionId = useAtomValue(checkoutAtoms.sessionId)

  const { handleTryToCapturaDataToMymetric } = useTryToCaptureDataToMymetric()

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (formPayload: CheckoutFormPayload) => {
      Sentry.setUser({
        name: formPayload.name,
        email: formPayload.email,
        phone: parseBrazilianPhone(formPayload.phone),
      })
      const mymetricData = await handleTryToCapturaDataToMymetric()

      if (
        isCheckoutFormPayloadWithLegacyCreditCard(formPayload) &&
        product &&
        offer
      ) {
        const body = await pagarmeRequestBodyFactories.toCreditCardOrder(
          product.id,
          formPayload,
          queryParams,
          isNewCheckout,
          isDirectCheckout,
          mymetricData,
          sessionId,
          offer
        )

        return legacyPaymentApi.tryToCreateLegacytOrderOnAdaptedContract(body)
      }

      if (isCheckoutFormPayloadWithCreditCard(formPayload) && offer) {
        const {
          fpp,
          fpc,
          ip,
          gaSessionId,
          pageLocation,
          distinctId,
          mmTracker,
        } = mymetricData

        const offerId = parseInt(offer.id.toString())

        const offerPayload = offerPaymentPayload(offer, formPayload)

        const payload: TryRequestPaymentPayload = {
          name: formPayload.name,
          email: formPayload.email,
          phone: parseBrazilianPhone(formPayload.phone),
          document: getOnlyNumbers(formPayload.document),
          origin: qp_origin ?? 'web',
          installments: formPayload.installments ?? offer?.installments,
          productSellGroupId: parseInt(offer.productSellGroupId.toString()),
          card: formPayload.card,
          offerId,
          ...offerPayload,
          customerId: await getGoogleAnalyticsClientId(),
          src: queryParams.src ?? null,
          isNewCheckout,
          isDirectCheckout,
          fpp,
          fpc,
          ip,
          mmTracker,
          pageLocation,
          gaSessionId,
          recapchaToken: formPayload.recaptchaToken,
          sessionId,
          recoveryId,
          distinctId,
          fullOrigin: window.location.href,
        }

        return paymentsApi.tryToCreatePayment(payload)
      }
      throw new Error('invalid payload')
    },
    onMutate: () => {
      setIsCreatingOrder(true)
    },
    onSuccess: (response, payload) => {
      if (!product || !offer) return

      setIsCreatingOrder(false)
      isRedirectingAfterSuccess(true)

      onPurchaseSuccess && onPurchaseSuccess(payload)

      const isFromAndroidApp = qp_origin?.includes('android') ?? false
      // Open deeplink back to the mobile app
      if (isFromAndroidApp) {
        setTimeout(() => {
          window.location.href = 'queimadiaria://app/success-checkout'
        }, 300)
      }

      const redirectUrl = addMarketingParamsToRedirectUrl(
        response.creditCardRedirectUrl ?? '',
        product.id.toString(),
        product.publicName,
        offer.fullPrice,
        response?.funnelToken
      )

      setTimeout(() => {
        window.location.href = isFromAndroidApp
          ? checkoutDetails?.origin ?? ''
          : redirectUrl
      }, 1300)
    },
    onError: (error: SerializedApiError) => {
      setPaymentCheckoutToFallback(true)

      recaptchaRef.current?.reset()
      if (error && error.status !== 403) {
        setIsCreatingOrder(false)
        setHasErrorCreatingOrder(true)
        setCreatingOrderErrorDetails(
          isSerializedApiError(error) ? error : undefined
        )

        return
      }
      checkIsSelfIndication(error) && setCreatingOrderErrorDetails(error)

      setIsCreatingOrder(false)
      ToastCustom({
        type: 'error',
        message: 'Por favor, verifique o reCAPTCHA novamente!',
      })
    },
  })

  return { tryToRequestPayment: mutate, isLoading: isPending, isError }
}
