import { parse } from 'qs'

/**
 * Extracts query parameters from a URL string.
 *
 * @param urlWithParams - The URL string possibly containing query parameters.
 * @returns An array containing the base URL and an object representing the query parameters.
 */
export function getQueryParamsFromUrl(
  urlWithParams: string
): [string, { [key: string]: string }] {
  if (!urlWithParams.includes('?')) return [urlWithParams, {}]

  const [url, queryParams] = urlWithParams.split('?')
  const params = parse(queryParams)

  return [url, params as { [key: string]: string }]
}
