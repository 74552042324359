import Cookies from 'js-cookie'

import { CheckoutFormPayload } from 'src/features/checkout/typings/checkoutFormTypings'
import { Subscription } from 'src/features/checkout/typings/planTypings'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useCheckoutDetails } from 'src/features/plan/checkout/hooks/useCheckoutDetails'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'

import { factoryFormOnPurchasePixels } from '../utils/factoryFormOnPurchasePixels'
import { fbq } from '../utils/fbq'
import { capitalize } from '../utils/pixelHelpers'

export function usePurchasedPixel() {
  const queryParams = useCheckoutParamsFromUrl()
  const { product } = useProduct()
  const { checkoutDetails } = useCheckoutDetails()
  const { offer } = useOffer()

  const { src } = queryParams

  const onEvent = (payload: CheckoutFormPayload) => {
    if (!checkoutDetails?.facebookPixels || !product) return

    const fabricatedFormPayload = factoryFormOnPurchasePixels({
      email: payload.email,
      name: payload.name,
      phone: payload.phone,
    })

    const facebookCookie = Cookies.get('_fbc')?.split('.')
    const fbc = facebookCookie ? facebookCookie[facebookCookie?.length - 1] : ''

    const event = {
      product: {
        id: product.id.toString(),
        name: product.publicName,
        category: `${product.publicName}`,
      },
      src: src ? { src } : {},
      value: offer?.fullPrice,
      currency: 'BRL',
      subscription: capitalize(Subscription.YEARLY),
      paymentMethod: capitalize(payload.paymentMethod),
      ...fabricatedFormPayload,
      fbc,
    }

    fbq('trackCustom', 'Purchase', event)
  }

  return onEvent
}
