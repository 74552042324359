import styled from '@emotion/styled'
import { Box, Flex, Icon, Popover, Text } from '@queimadiaria/ui'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai'
import { FC, useMemo, useState } from 'react'

import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/useDigitalCheckoutContext'

import { useCheckoutParamsFromUrl } from '../../layout/hooks/useCheckoutParamsFromUrl'
import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { usePageInfoStore } from '../hooks/usePageInfoStore'

export const SubHeader: FC = () => {
  const { refundMessage } = useCheckoutParamsFromUrl()
  const { specialOffer } = usePageInfoStore()
  const { chosenOffer } = useOffer()
  const { configCheckout } = useDigitalCheckoutContext()

  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isOpenPopover, setIsOpenPopover] = useAtom(checkoutAtoms.isOpenPopover)

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)

  const setIsOpenPopoverAndMouseOver = () => {
    setIsOpenPopover(!isOpenPopover)
    setIsMouseOver(false)
  }

  const setMouseOver = () => {
    setIsMouseOver(!isMouseOver)
  }

  const titlePage = useMemo(() => {
    const defaultTitle = 'Checkout Seguro'

    if (!chosenOffer || !configCheckout) return defaultTitle

    return (
      configCheckout.renderProps.specialOfferConfig?.[
        !!specialOffer ? 'special' : 'default'
      ].title || defaultTitle
    )
  }, [specialOffer, chosenOffer])

  if (!isNewCheckout) {
    return (
      <div>
        <Box
          sx={{
            marginBlockStart: '16px',
            display: 'flex',
            alignItems: 'center',
            borderBlockEnd: '1px solid #E8E8E8',
          }}
        >
          <Text variant='headline-3'>{titlePage}</Text>
          <Icon sx={{ paddingLeft: '8px' }} name='lock-filled' />
        </Box>
        <Box
          sx={{
            paddingBlockStart: '8px',
            paddingBlockEnd: '32px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {refundMessage && (
            <>
              <Text variant='body-2'>
                Experimente 30 dias! E se não gostar,{' '}
                <Bolder>fazemos o reembolso de 100% do valor!</Bolder>
              </Text>
              {isOpenPopover ? (
                <Popover
                  content={
                    <PopoverMessage
                      setIsOpenPopoverAndMouseOver={
                        setIsOpenPopoverAndMouseOver
                      }
                    />
                  }
                  placement='right-center'
                  isVisible={isOpenPopover}
                >
                  <Icon
                    sx={{
                      paddingLeft: '12px',
                      paddingBottom: '10px',
                      color: '#fc580a',
                    }}
                    name='help-filled'
                    id='icon'
                    onClick={setIsOpenPopoverAndMouseOver}
                  />
                </Popover>
              ) : (
                <Icon
                  sx={{
                    paddingLeft: '12px',
                    paddingBottom: '10px',
                    color: '#fc580a',
                  }}
                  name={isMouseOver ? 'help-filled' : 'help'}
                  id='icon'
                  onClick={setIsOpenPopoverAndMouseOver}
                  onMouseOver={setMouseOver}
                  onMouseOut={setMouseOver}
                />
              )}
            </>
          )}
        </Box>
      </div>
    )
  }

  return (
    <Box sx={{ padding: ['0px 32px 0px 32px', '32px 32px 0px 32px'] }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderBlockEnd: '1px solid #EEEEEE',
          paddingBlockEnd: '8px',
          marginBlockEnd: !refundMessage && '16px',
        }}
      >
        <Text variant='headline-3'>{titlePage}</Text>
        <img
          style={{ paddingLeft: '8px' }}
          src='/assets/lockGray.svg'
          alt='lock-filled'
        />
      </Box>
      {refundMessage && (
        <Box
          sx={{
            paddingBlockStart: '8px',
            paddingBlockEnd: '32px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <>
            <Text variant='body-2'>
              Experimente 30 dias! E se não gostar,{' '}
              <Bolder>fazemos o reembolso de 100% do valor!</Bolder>
            </Text>
            {isOpenPopover ? (
              <Popover
                content={
                  <PopoverMessage
                    setIsOpenPopoverAndMouseOver={setIsOpenPopoverAndMouseOver}
                  />
                }
                placement='right-center'
                isVisible={isOpenPopover}
              >
                <Icon
                  sx={{
                    paddingLeft: '12px',
                    paddingBottom: '10px',
                    color: '#fc580a',
                  }}
                  name='help-filled'
                  id='icon'
                  onClick={setIsOpenPopoverAndMouseOver}
                />
              </Popover>
            ) : (
              <Icon
                sx={{
                  paddingLeft: '12px',
                  paddingBottom: '10px',
                  color: '#fc580a',
                }}
                name={isMouseOver ? 'help-filled' : 'help'}
                id='icon'
                onClick={setIsOpenPopoverAndMouseOver}
                onMouseOver={setMouseOver}
                onMouseOut={setMouseOver}
              />
            )}
          </>
        </Box>
      )}
    </Box>
  )
}

const Bolder = styled.b({
  fontWeight: 500,
})

interface PopoverMessageProps {
  setIsOpenPopoverAndMouseOver: () => void
}

const PopoverMessage: FC<PopoverMessageProps> = ({
  setIsOpenPopoverAndMouseOver,
}) => {
  return (
    <Flex sx={{ flexDirection: 'column', rowGap: '8px' }}>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Text variant='subheadline-2'>Reembolso</Text>
        <Icon
          sx={{
            lineHeight: 'inherit',
            color: '#fc580a',
          }}
          name='close'
          id='icon'
          onClick={setIsOpenPopoverAndMouseOver}
        />
      </Flex>
      <Box>
        <Text variant='body-2' color='#6E6C7C'>
          Bastar mandar um email para <br />
          suporte@queimadiaria.com.br, <br />
          nos próximos 30 dias, e <br />
        </Text>
        <Text variant='body-2'>
          <Bolder>reembolsamos todo o valor pago!</Bolder>
        </Text>
      </Box>
    </Flex>
  )
}
