import { changeUserEmailApi } from 'src/infra/api'
import { createUnknownSerializedApiError } from 'src/utils/createUnknownSerializedApiError'

import { AlterEmail } from '../typings/checkoutFormTypings'
import { fromErrorChangeUserEmailToSerializedApiError } from '../utils/fromErrorChangeUserEmailToSerializedApiError'

async function putChangeUserEmailApi(body: AlterEmail) {
  return changeUserEmailApi
    .url(`/users/freemium/change-user`)
    .put(body)
    .internalError(error => {
      throw fromErrorChangeUserEmailToSerializedApiError(error.json)
    })
    .unauthorized(error => {
      throw fromErrorChangeUserEmailToSerializedApiError(error.json)
    })
    .badRequest(error => {
      throw fromErrorChangeUserEmailToSerializedApiError(error.json)
    })
    .fetchError(() => {
      throw createUnknownSerializedApiError()
    })
    .json()
}

export const changeEmailApi = {
  putChangeUserEmailApi,
}
