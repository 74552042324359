import { useAtom } from 'jotai'
import { useEffect } from 'react'

import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'

import { checkoutAtoms } from '../atoms/checkoutAtoms'

export function useWatchSalesRecoveryParams() {
  const { isSalesRecovery } = useCheckoutParamsFromUrl()

  const [, setShowMorePaymentMethods] = useAtom(
    checkoutAtoms.showMorePaymentMethods
  )

  useEffect(() => {
    if (isSalesRecovery) setShowMorePaymentMethods()
  }, [])
}
