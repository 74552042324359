import cardValidator from 'card-validator'

/**
 * Checks if a given credit card number belongs to a Discover card.
 *
 * @param creditCard - The credit card number to check.
 * @returns A boolean indicating whether the credit card is a Discover card or not.
 */
export const isDiscoverCard: (creditCard: string) => boolean = creditCard => {
  return cardValidator.number(creditCard).card?.type === 'discover'
}

/**
 * Checks if a given credit card number belongs to a American Express card.
 *
 * @param creditCard - The credit card number to check.
 * @returns A boolean indicating whether the credit card is a American Express card or not.
 */
export const isAmericanExpressCard: (
  creditCard: string
) => boolean = creditCard => {
  return cardValidator.number(creditCard).card?.type === 'american-express'
}

/**
 * Checks if a given credit card number belongs to a Diners Club card.
 *
 * @param creditCard - The credit card number to check.
 * @returns A boolean indicating whether the credit card is a Diners Club card or not.
 */
export const isDinersClubCard: (creditCard: string) => boolean = creditCard => {
  return cardValidator.number(creditCard).card?.type === 'diners-club'
}

/**
 * Checks if a given credit card number belongs to either an American Express or Discover card.
 *
 * @param creditCardNumber - Optional. The credit card number to check.
 * @returns A boolean indicating whether the credit card is either an American Express or Discover card or not.
 */
export const isAmericanExpressOrDiscover = (creditCardNumber?: string) => {
  if (!creditCardNumber) return false
  const isAmericanExpressCardType = isAmericanExpressCard(creditCardNumber)
  const isDiscoverCardType = isDiscoverCard(creditCardNumber)
  return isAmericanExpressCardType || isDiscoverCardType
}

const americanExpressOrDinersClubMask: (
  creditCard: string
) => string = creditCard => {
  let value = creditCard.replace(/\D/g, '')
  value = value.replace(/^(\d{4})(\d)/g, '$1 $2')
  value = value.replace(/^(\d{4})\s(\d{6})(\d)/g, '$1 $2 $3')
  return value
}
const maskOthersCreditCard: (creditCard: string) => string = creditCard => {
  let value = creditCard.replace(/\D/g, '')
  value = value.replace(/^(\d{4})(\d)/g, '$1 $2')
  value = value.replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3')
  value = value.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4')
  return value
}
/**
 * Masks a given credit card number based on its type.
 *
 * @param creditCardNumber - Optional. The credit card number to mask.
 * @returns The masked credit card number, or an empty string if no number provided.
 */
export const maskCreditCardNumber = (creditCardNumber?: string) => {
  if (!creditCardNumber) return ''
  if (
    isAmericanExpressCard(creditCardNumber) ||
    isDinersClubCard(creditCardNumber)
  )
    return americanExpressOrDinersClubMask(creditCardNumber)
  return maskOthersCreditCard(creditCardNumber)
}

/**
 * Removes any spaces from a masked credit card number to return its unmasked form.
 *
 * @param creditCardNumber - The masked credit card number to unmask.
 * @returns The unmasked credit card number.
 */
export const unMaskedCreditCardNumber: (
  creditCardNumber: string
) => string = creditCardNumber => {
  const creditCardList: string[] = creditCardNumber.split(' ')
  let creditCardNumberUnMask = ''
  creditCardList.forEach(number => {
    creditCardNumberUnMask = creditCardNumberUnMask + number
  })
  return creditCardNumberUnMask
}

/**
 * Parses a string representing an expiry date in the format "MM/YY" into an object containing month, year, and date.
 *
 * @param value - The string representing the expiry date to parse.
 * @returns An object containing the parsed month, year, and date.
 */
export const parseExpiryDate = (value: string) => {
  if (!RegExp(/^(0[1-9]|1[0-2])\/\d{2}$/).test(value))
    throw new Error('Invalid input format')

  const [rawMonth, rawYear] = value.split('/')

  const month = parseInt(rawMonth)
  const year = parseInt(rawYear) + 2000

  const date = new Date(year, month - 1)
  return { month: rawMonth, year: year.toString(), asDate: date }
}

/**
 * Joins month and year strings to form an expiry date string in the format "MM/YY".
 *
 * @param month - The month part of the expiry date.
 * @param year - The year part of the expiry date.
 * @returns The joined expiry date string.
 */
export const joinExpiryDate = (month: string, year: string) => {
  return `${month}/${year.replace(/^20/, '')}`
}

/**
 * Validates an expiration date string.
 *
 * @param value - The expiration date string to validate.
 * @returns A boolean indicating whether the expiration date is valid or not.
 */
export const validateExpirationDate = (value: string) => {
  return cardValidator.expirationDate(value).isValid
}
