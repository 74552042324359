import { isValidPhone } from '@brazilian-utils/brazilian-utils'
import styled from '@emotion/styled'
import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  LegacyTextInput,
  Text,
} from '@queimadiaria/ui'
import { useAtomValue } from 'jotai'
import { ChangeEvent, FC, memo, useEffect } from 'react'
import {
  Control,
  Controller,
  useFormContext,
  UseFormTrigger,
} from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { useAddedContactInfoMixPanel } from 'src/features/analytics/hooks/useAddedContactInfoMixPanel'
import { useAddedContactInfoPixel } from 'src/features/analytics/hooks/useAddedContactInfoPixel'
import { useCupomDigitalCheckoutStore } from 'src/features/CupomDigitalCheckout/hooks/useCupomDigitalCheckoutStore'
import { useDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/useDigitalCheckoutContext'

import { env } from '../../../configs/env'
import { useCheckoutParamsFromUrl } from '../../layout/hooks/useCheckoutParamsFromUrl'
import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { useFormFilledServelessLeads } from '../hooks/useFormFilledServelessLeads'
import { useOnContactInfoFilledCallback } from '../hooks/useOnContactInfoFilledCallback'
import { useUpdateLoginEmail } from '../hooks/useUpdateLoginEmail'
import { useUpdateParamEmail } from '../hooks/useUpdateParamEmail'
import { CheckoutFormPayload } from '../typings/checkoutFormTypings'
import { phoneMask } from '../utils/checkoutValidationPhone'
import { cleanupPhoneNumber } from '../utils/cleanupPhoneNumber'

const isSSR = typeof window === 'undefined'

export const DataPersonalFormContainer: FC = () => {
  const { control, trigger, setFocus, getValues } =
    useFormContext<CheckoutFormPayload>()
  const addedContactInfoPixel = useAddedContactInfoPixel()
  const addedContactInfoMixPanel = useAddedContactInfoMixPanel()
  const checkoutParams = useCheckoutParamsFromUrl()

  useFormFilledServelessLeads()

  useEffect(() => {
    const { name, email, phone, withEmailConfirmationEnabled } = checkoutParams
    const shouldFocusOnDocument = !!name && !!email && !!phone

    if (!withEmailConfirmationEnabled) {
      const noHasCPF = !getValues('document')

      if (shouldFocusOnDocument && noHasCPF) setFocus('document')
    } else {
      const noHasEmailConfirmation = !getValues('emailConfirmation')
      if (shouldFocusOnDocument && noHasEmailConfirmation)
        setFocus('emailConfirmation')
    }
  }, [checkoutParams])

  useOnContactInfoFilledCallback(addedContactInfoPixel)
  useOnContactInfoFilledCallback(addedContactInfoMixPanel)

  return <DataPersonalForm control={control} trigger={trigger} />
}

const DataPersonalForm: FC<{
  control: Control<CheckoutFormPayload>
  trigger: UseFormTrigger<CheckoutFormPayload>
}> = memo(({ control, trigger }) => {
  const checkoutParams = useCheckoutParamsFromUrl()
  const { withEmailConfirmationEnabled } = checkoutParams
  const devEnv = env.ADYEN_ENVIRONMENT === 'test'
  const {
    form: { isDisabledField },
  } = useDigitalCheckoutContext()

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
  const isDirectCheckout = useAtomValue(checkoutAtoms.isDirectCheckout)
  const isUpdateLoginEmail = useAtomValue(checkoutAtoms.isUpdateLoginEmail)
  const customerData = useAtomValue(checkoutAtoms.customerData)
  const { handleEmailChange } = useUpdateLoginEmail()
  const { handleIsUpdateParamEmail } = useUpdateParamEmail()
  const isUpdateParamEmail = useAtomValue(checkoutAtoms.isUpdateParamEmail)
  const hasParamEmail = useAtomValue(checkoutAtoms.hasParamEmail)
  const { setFocusedDigitalCheckout } = useCupomDigitalCheckoutStore()

  useEffect(() => {
    if (isNewCheckout && customerData.phone) {
      trigger('phone')
    }
  }, [customerData.phone])

  if (!isNewCheckout) {
    return (
      <Flex
        sx={{
          flexDirection: 'column',
          margin: '0 0 16px 0',
          width: ['100%', '100%', '50%', '50%'],
        }}
      >
        <Text variant='subheadline-2'>Seus dados.</Text>
        <Controller
          name='name'
          control={control}
          // ######## IMPORTANT ########
          // Muito cuidado ao alterar esses valores, rodar defaultParams
          // no SSR pode resultar em problemas de caching com
          // dados de usuários sendo expostos para outros usuários
          defaultValue={isSSR ? '' : checkoutParams.name ?? ''}
          render={({
            field: { onChange, onBlur, value, name },
            fieldState: { invalid, error },
          }) => (
            <FormControl mt='medium' hasError={invalid}>
              <FormLabel htmlFor={name}>Nome</FormLabel>
              <LegacyTextInput
                css={{ fontSize: '14px' }}
                placeholder='Digite seu nome completo'
                id={name}
                name={name}
                type='text'
                inputMode='text'
                disabled={isDisabledField(name)}
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={onBlur}
              />
              <FormHelperText errorMessage={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name='email'
          control={control}
          // ######## IMPORTANT ########
          // Vide comentário acima
          defaultValue={isSSR ? '' : checkoutParams.email ?? ''}
          render={({
            field: { onBlur, onChange, value, name },
            fieldState: { invalid, error },
          }) => {
            const handleFocus = () => {
              setFocusedDigitalCheckout(true)
            }
            const handleBlur = () => {
              setFocusedDigitalCheckout(false)
              onBlur()
            }
            return (
              <FormControl mt='medium' hasError={invalid}>
                <FormLabel htmlFor={name}>
                  E-mail para acessar a plataforma
                </FormLabel>
                <LegacyTextInput
                  css={{ fontSize: '14px' }}
                  placeholder='Digite seu e-mail'
                  id={name}
                  name={name}
                  type={name}
                  inputMode='email'
                  value={value}
                  onChange={e => onChange(e.target.value.trim())}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  disabled={isDisabledField(name)}
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )
          }}
        />
        {withEmailConfirmationEnabled && devEnv ? (
          <Controller
            name='emailConfirmation'
            control={control}
            render={({
              field: { onBlur, onChange, value, name, ref },
              fieldState: { invalid, error },
            }) => (
              <FormControl mt='medium' hasError={invalid}>
                <FormLabel htmlFor={name}>Confirme seu email</FormLabel>
                <LegacyTextInput
                  css={{ fontSize: '14px' }}
                  placeholder='Digite seu e-mail'
                  id={name}
                  name={name}
                  type={name}
                  inputMode='email'
                  value={value}
                  onChange={e => onChange(e.target.value.trim())}
                  onBlur={onBlur}
                  disabled={isDisabledField(name)}
                  onPaste={e => {
                    e.preventDefault()
                    return false
                  }}
                  autoComplete='off'
                  ref={ref}
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )}
          />
        ) : null}

        <Box
          sx={{
            flex: '1 0',
            maxWidth: !isNewCheckout ? '200px' : 'auto',
            width: !isNewCheckout ? 'auto' : '100%',
          }}
        >
          <Controller
            name='phone'
            control={control}
            // ######## IMPORTANT ########
            // Vide comentário acima
            defaultValue={isSSR ? '' : cleanupPhoneNumber(checkoutParams.phone)}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <FormControl mt='medium' hasError={invalid}>
                <FormLabel htmlFor={name}>Telefone</FormLabel>
                <NumberFormat
                  css={{ fontSize: '14px' }}
                  placeholder='Digite seu telefone'
                  id={name}
                  name={name}
                  value={value}
                  type='tel'
                  inputMode='tel'
                  format={phoneMask}
                  disabled={isDisabledField(name)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChange(e.target.value)
                  }
                  onBlur={onBlur}
                  customInput={LegacyTextInput}
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )}
          />
          <Controller
            name='document'
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, error },
            }) => {
              const handleFocus = () => {
                setFocusedDigitalCheckout(true)
              }
              const handleBlur = () => {
                setFocusedDigitalCheckout(false)
                onBlur()
              }

              return (
                <FormControl mt='medium' hasError={invalid}>
                  <FormLabel htmlFor={name}>CPF</FormLabel>
                  <NumberFormat
                    css={{ fontSize: '14px' }}
                    getInputRef={ref}
                    placeholder='Digite seu CPF'
                    id={name}
                    name={name}
                    type='tel'
                    value={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onChange(e.target.value)
                    }}
                    onBlur={handleBlur}
                    format='###.###.###-##'
                    customInput={LegacyTextInput}
                    onFocus={handleFocus}
                    disabled={isDisabledField(name)}
                  />
                  <FormHelperText errorMessage={error?.message} />
                </FormControl>
              )
            }}
          />
        </Box>
      </Flex>
    )
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '0px 32px',
          display: isUpdateParamEmail
            ? 'block'
            : isUpdateLoginEmail
            ? 'block'
            : 'none',
        }}
      >
        <Controller
          name='email'
          control={control}
          // ######## IMPORTANT ########
          // Vide comentário acima
          defaultValue={
            isSSR ? '' : (checkoutParams.email || customerData.email) ?? ''
          }
          render={({
            field: { onBlur, onChange, value, name },
            fieldState: { invalid, error },
          }) => (
            <FormControl mt='medium' hasError={invalid}>
              <NewFormLabel htmlFor={name}>
                E-mail para acessar a plataforma
              </NewFormLabel>
              <LegacyTextInput
                style={{
                  background: '#FFFFFF',
                  boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                }}
                css={{ fontSize: '14px' }}
                placeholder='Digite seu e-mail'
                id={name}
                name={name}
                type={name}
                inputMode='email'
                value={value}
                onChange={e => {
                  return onChange(e.target.value.trim())
                }}
                onBlur={onBlur}
              />
              <FormHelperText errorMessage={error?.message} />
            </FormControl>
          )}
        />
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            hasParamEmail ? handleIsUpdateParamEmail() : handleEmailChange()
          }
        >
          <Text
            variant='body-1'
            css={{
              fontSize: '14px',
              lineHeight: '150%',
              marginBlockStart: '6px',
            }}
            color='#0061F9'
          >
            Salvar e-mail
          </Text>
        </Box>
      </Box>

      <Flex
        sx={{
          flexDirection: 'column',
          margin: '0 0 16px 0',
          width: '100%',
          padding: '0px 32px',
        }}
      >
        <Box
          sx={{
            flex: '1 0',
            maxWidth: !isNewCheckout ? '200px' : 'auto',
            width: !isNewCheckout ? 'auto' : '100%',
          }}
        >
          {isDirectCheckout && (
            <>
              <Controller
                name='name'
                control={control}
                // ######## IMPORTANT ########
                // Muito cuidado ao alterar esses valores, rodar defaultParams
                // no SSR pode resultar em problemas de caching com
                // dados de usuários sendo expostos para outros usuários
                defaultValue={
                  isSSR ? '' : (checkoutParams.name || customerData.name) ?? ''
                }
                render={({
                  field: { onChange, onBlur, value, name },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl mt='medium' hasError={invalid}>
                    <NewFormLabel htmlFor={name}>Nome</NewFormLabel>
                    <LegacyTextInput
                      css={{ fontSize: '14px' }}
                      style={{
                        background: '#FFFFFF',
                        boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                        borderRadius: '4px',
                      }}
                      placeholder='Digite seu nome completo'
                      id={name}
                      name={name}
                      type='text'
                      inputMode='text'
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      onBlur={onBlur}
                    />
                    <FormHelperText errorMessage={error?.message} />
                  </FormControl>
                )}
              />
              {!hasParamEmail && (
                <Controller
                  name='email'
                  control={control}
                  // ######## IMPORTANT ########
                  // Vide comentário acima
                  defaultValue={
                    isSSR
                      ? ''
                      : (checkoutParams.email || customerData.email) ?? ''
                  }
                  render={({
                    field: { onBlur, onChange, value, name },
                    fieldState: { invalid, error },
                  }) => (
                    <FormControl mt='medium' hasError={invalid}>
                      <NewFormLabel htmlFor={name}>
                        E-mail para acessar a plataforma
                      </NewFormLabel>
                      <LegacyTextInput
                        css={{ fontSize: '14px' }}
                        style={{
                          background: '#FFFFFF',
                          boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                          borderRadius: '4px',
                        }}
                        placeholder='Digite seu e-mail'
                        id={name}
                        name={name}
                        type={name}
                        inputMode='email'
                        value={value}
                        onChange={e => onChange(e.target.value.trim())}
                        onBlur={onBlur}
                      />
                      <FormHelperText errorMessage={error?.message} />
                    </FormControl>
                  )}
                />
              )}
            </>
          )}

          {(!isValidPhone(customerData.phone) || isDirectCheckout) && (
            <Controller
              name='phone'
              defaultValue={
                isSSR
                  ? ''
                  : isDirectCheckout
                  ? (checkoutParams.phone || customerData.phone) ?? ''
                  : ''
              }
              control={control}
              // ######## IMPORTANT ########
              // Vide comentário acima
              render={({
                field: { onChange, onBlur, value, name },
                fieldState: { invalid, error },
              }) => (
                <FormControl mt='medium' hasError={invalid}>
                  <NewFormLabel htmlFor={name}>Telefone</NewFormLabel>
                  <NumberFormat
                    style={{
                      background: '#FFFFFF',
                      boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                      borderRadius: '4px',
                    }}
                    css={{ fontSize: '14px' }}
                    placeholder='Digite seu telefone'
                    id={name}
                    name={name}
                    value={value}
                    type='tel'
                    inputMode='tel'
                    format={phoneMask}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChange(e.target.value)
                    }
                    onBlur={onBlur}
                    customInput={LegacyTextInput}
                  />
                  <FormHelperText errorMessage={error?.message} />
                </FormControl>
              )}
            />
          )}
          <Controller
            name='document'
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, error },
            }) => (
              <FormControl mt='medium' hasError={invalid}>
                <NewFormLabel htmlFor={name}>CPF</NewFormLabel>
                <NumberFormat
                  css={{ fontSize: '14px' }}
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '0px 2.15px 4.3px rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                  }}
                  getInputRef={ref}
                  placeholder='Digite seu CPF'
                  id={name}
                  name={name}
                  type='tel'
                  value={value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  format='###.###.###-##'
                  customInput={LegacyTextInput}
                />
                <FormHelperText errorMessage={error?.message} />
              </FormControl>
            )}
          />
        </Box>
      </Flex>
    </>
  )
})

const NewFormLabel = styled(FormLabel)`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  letter-spacing: -0.02em;

  color: #616161;
  margin-block-end: 6px;
`

export default DataPersonalFormContainer
