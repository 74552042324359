import styled from '@emotion/styled'

export const SpinnerComponent: React.FC = () => {
  return <Spinner src='/assets/Ellipse.svg' alt='Carregando...' />
}

const Spinner = styled.img`
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
