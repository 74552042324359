import { Installment } from 'src/features/plan/offer/typings/offerTypings'

/**
 * Generates a sequence of values based on the provided creator function.
 *
 * @param n - The number of elements in the sequence.
 * @param creator - A function that creates each element in the sequence based on the index.
 * @returns An array containing the generated sequence.
 */
export function sequence<T>(n: number, creator: (index: number) => T) {
  return Array.from({ length: n }, (_, index) => creator(index))
}

/**
 * Creates a list of month labels.
 *
 * @returns An array containing labels for each month (01 to 12).
 */
export function createListOfMonthsLabels() {
  const totalMonths = 12

  return sequence(totalMonths, index => {
    const month = index + 1
    const october = 10
    return month >= october ? month.toString() : `0${month}`
  })
}

/**
 * Creates a list of year labels starting from the current year up to a specified number of years from now.
 *
 * @param numOfYearsFromNow - The number of years from the current year to generate labels for.
 * @returns An array containing labels for each year.
 */
export function createListOfYearsLabelsFromNow(numOfYearsFromNow: number) {
  const currentYear = new Date().getFullYear()

  return sequence(numOfYearsFromNow, i => (i + currentYear).toString())
}

/**
 * Creates a list of installment labels based on the number of installments and full price.
 *
 * @param numOfInstallments - The number of installments to generate labels for.
 * @param fullPrice - The full price of the item.
 * @returns An array containing objects representing each installment, with count and formatted price.
 */
export function createListOfInstallmentsLabelsFromNow(
  numOfInstallments: number,
  fullPrice: number
) {
  const installments = sequence(numOfInstallments, i => i + 1).reverse()

  const format = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 2,
  }).format

  return installments.map(installment => ({
    count: installment,
    price: format(fullPrice / installment),
  }))
}

/**
 * Creates a list of installment items based on the number of installments and full price.
 *
 * @param numOfInstallments - The number of installments to generate items for.
 * @param fullPrice - The full price of the item.
 * @returns An array containing objects representing each installment item, with installment count and formatted price.
 */
export function createListOfInstallmentsItemsFromNow(
  numOfInstallments: number,
  fullPrice: number
) {
  const installments = sequence(numOfInstallments, i => i + 1).reverse()

  const format = new Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
    useGrouping: false,
  }).format

  return installments.map(
    (installment): Installment => ({
      installment: installment,
      price: format(fullPrice / installment),
    })
  )
}
