import { useAtom } from 'jotai'
import { useEffect } from 'react'

import { useCheckoutParamsFromUrl } from '../../layout/hooks/useCheckoutParamsFromUrl'
import { checkoutAtoms } from '../atoms/checkoutAtoms'

export const useWatchBilletParams = () => {
  const { isBilletMethodEnabled } = useCheckoutParamsFromUrl()

  const [, setWatchBillet] = useAtom(
    checkoutAtoms.addBilletAsAvailablePaymentMethod
  )
  useEffect(() => {
    if (isBilletMethodEnabled) setWatchBillet()
  }, [isBilletMethodEnabled])
}
