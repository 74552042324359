import { Plan, Subscription } from 'src/features/checkout/typings/planTypings'

export const getPixelsIdsFromPlan = (plan: Plan) => {
  return plan?.metadata?.checkout?.pixel?.ids ?? []
}

export const getFirstPixelIdFromPlan = (plan: Plan) => {
  const pixels = getPixelsIdsFromPlan(plan)
  return hasAnyPixelOnPlan(plan) ? pixels[0] : undefined
}

export const hasAnyPixelOnPlan: (plan: Plan) => boolean = plan => {
  return getPixelsIdsFromPlan(plan).length > 0
}

export const toJoinAliasSubscription = (
  alias: string,
  subscription: Subscription
) => {
  return `${capitalize(alias)} ${capitalize(subscription)}`
}

export const capitalize: (word: string) => string = word => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}
