import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'

import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { CheckoutFormPayloadOfAllTypes } from '../typings/checkoutFormTypings'
import { PaymentMethods } from '../typings/legacyCheckoutTypings'
import { isCheckoutFormPayloadWithLegacyCreditCard } from '../utils/checkoutFormTypeGuards'

export function useAnotherFormOfPayment() {
  const [, setIsPaymentByBillet] = useAtom(checkoutAtoms.isPaymentByBillet)
  const [, setIsPaymentByPix] = useAtom(checkoutAtoms.isPaymentByPix)
  const [, clearErrors] = useAtom(paymentAtoms.clearErrors)
  const { getValues, setValue, reset } =
    useFormContext<CheckoutFormPayloadOfAllTypes>()

  const handlerUseAnotherFormOfPayment = useCallback(() => {
    setValue('paymentMethod', PaymentMethods.CreditCard)
    clearErrors()
    const values = getValues()
    if (isCheckoutFormPayloadWithLegacyCreditCard(values)) {
      reset({
        ...values,
        creditCardNumber: '',
        creditCardName: '',
        cardExpiration: '',
        cvv: '',
      })
      document.getElementsByName('creditCardNumber')[0]?.focus()
    }

    setIsPaymentByBillet(false)
    setIsPaymentByPix(false)
  }, [])

  return { handlerUseAnotherFormOfPayment }
}
