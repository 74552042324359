import styled from '@emotion/styled'
import { Box, Button, Flex, Text } from '@queimadiaria/ui'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai'
import Image from 'next/image'
import { FC, useCallback, useMemo, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useFormContext } from 'react-hook-form'

import { env } from 'src/configs/env'
import { usePageInfoStore } from 'src/features/checkout/hooks/usePageInfoStore'
import { calculateSpecialOffferPercentage } from 'src/features/checkout/utils/specialOfferCheckout'
import { CrossSellCheckoutErrorMessage } from 'src/features/crossSellCheckout/components/CrossSellCheckoutErrorMessage'
import { useIsCrossSellCheckout } from 'src/features/crossSellCheckout/hooks/useIsCrossSellCheckout'
import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'
import { useIsOfferFormat } from 'src/features/plan/offer/hooks/useIsOfferFormat'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'
import { usePaymentDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/modules/payment/usePaymentDigitalCheckoutContext'
import { formatCurrency } from 'src/utils/currency'
import { isMonthlySubscriptionOrDirectFallback } from 'src/utils/isMonthlySubscriptionOrDirectFallback'

import { checkoutAtoms } from '../../checkout/atoms/checkoutAtoms'
import { CheckoutErrorMessage } from '../../checkout/components/CheckoutErrorMessage'
import { Installments } from '../../checkout/typings/checkoutFormTypings'
import { useImages } from '../hooks/useImages'

interface SubmitDetailsProps {
  isInstallmentsEnabled: boolean
  submitButtonLabel: string
}

export const SubmitDetailsContainer: FC<SubmitDetailsProps> = ({
  isInstallmentsEnabled,
  submitButtonLabel,
}) => {
  return (
    <SubmitDetails
      isInstallmentsEnabled={isInstallmentsEnabled}
      submitButtonLabel={submitButtonLabel}
    />
  )
}

const SubmitDetails: FC<SubmitDetailsProps> = ({
  isInstallmentsEnabled,
  submitButtonLabel,
}) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext()
  const { offer } = useOffer()

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const [, setRecaptchaRef] = useAtom(checkoutAtoms.recaptchaRef)
  const isCreatingOrder = useAtomValue(paymentAtoms.isCreatingOrder)
  const isSubmitButtomDisabled = useAtomValue(
    checkoutAtoms.isSubmitButtomDisabled
  )
  const { selectedMethodPayment, isAbleFinishBuy } =
    usePaymentDigitalCheckoutContext()
  const isCrossSellCheckout = useIsCrossSellCheckout()

  const isOfferFormat = useIsOfferFormat()

  const onChangeRecaptcha = useCallback(() => {
    setRecaptchaRef(recaptchaRef)
  }, [])

  return (
    <>
      {offer?.disclaimer && (
        <Box
          sx={{
            marginTop: '16px',
            textAlign: 'justify',
          }}
        >
          <Text variant='body-2'>{offer.disclaimer}</Text>
        </Box>
      )}

      <Flex
        sx={{
          flexDirection: 'column',
          marginBlockStart: selectedMethodPayment !== 'pix' ? '32px' : '20px',
          marginBlockEnd:
            selectedMethodPayment !== 'pix'
              ? [null, null, '16px', '16px']
              : '24px',
          borderBlockStart: '1px solid #E8E8E8',
        }}
      >
        {isCrossSellCheckout ? (
          <CrossSellCheckoutErrorMessage />
        ) : (
          <CheckoutErrorMessage />
        )}

        <Flex
          sx={{
            alignItems: 'flex-end',
            justifyContent: ['center', 'center', 'flex-start'],
            flexFlow: 'wrap',
          }}
        >
          {isOfferFormat && <OfferDescription />}
          <Flex
            sx={{
              flexDirection: selectedMethodPayment !== 'pix' ? 'column' : 'row',
              alignItems:
                selectedMethodPayment !== 'pix' ? undefined : 'flex-end',
              gap: selectedMethodPayment !== 'pix' ? 0 : 20,
              marginBlockStart: isOfferFormat ? '8px' : '32px',
              width: '100%',
            }}
          >
            {!isOfferFormat && (
              <OfferDetails isInstallmentsEnabled={isInstallmentsEnabled} />
            )}

            {isAbleFinishBuy && (
              <>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  style={{
                    marginBlockStart: 8,
                    marginBlockEnd: errors && errors.recaptchaToken ? 2 : 24,
                  }}
                  sitekey={`${env.RECAPTCHA_SITE_KEY}`}
                  onChange={(value: string | null) => {
                    onChangeRecaptcha()
                    setValue('recaptchaToken', value)
                  }}
                />
                {errors && errors.recaptchaToken && (
                  <FormHelperText>
                    {errors.recaptchaToken.message}
                  </FormHelperText>
                )}
              </>
            )}

            <Flex
              sx={{
                gap: [0, 0, 15],
                flexDirection: ['column', 'column', 'row'],
              }}
            >
              {isAbleFinishBuy && (
                <Button
                  loading={isCreatingOrder}
                  children={
                    <Text
                      as='span'
                      variant='subheadline-1'
                      css={{ fontSize: '16px', lineHeight: '0px' }}
                    >
                      {submitButtonLabel}
                    </Text>
                  }
                  disabled={isSubmitButtomDisabled}
                  size='large'
                  backgroundColor='#005FF2'
                  sx={{
                    width: ['100%', '100%', '50%'],
                    '&:disabled': {
                      '&:hover': {
                        opacity: 1,
                      },
                    },
                  }}
                />
              )}
              <Stamp src='/assets/selossl.svg' alt='Site Protegido com SSL' />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

interface OfferDetailsProps {
  isInstallmentsEnabled: boolean
}
const OfferDetails: FC<OfferDetailsProps> = ({ isInstallmentsEnabled }) => {
  const { watch } = useFormContext<Installments>()
  const { product } = useProduct()
  const { logo } = useImages()
  const { chosenOffer, offerRecurrence } = useOffer()
  const { specialOffer } = usePageInfoStore()
  const { selectedMethodPayment } = usePaymentDigitalCheckoutContext()

  const displayOfferRecurrence = useMemo(() => {
    return offerRecurrence !== '' ? `/${offerRecurrence}` : offerRecurrence
  }, [offerRecurrence])

  const isSpecialOffer = !!specialOffer

  const fullPrice = chosenOffer?.fullPrice || 0
  const displayPrice = chosenOffer?.displayPrice || 0

  const percentage = calculateSpecialOffferPercentage(fullPrice, displayPrice)
  const offerDiscount = `${percentage}% DESCONTO`

  const installmentsCount = Number(
    watch('installments') || chosenOffer?.installments
  )
  const installment = chosenOffer?.installmentsItems.find(
    item => item.installment === installmentsCount
  )

  const isSubscriptionMonthly = isMonthlySubscriptionOrDirectFallback(product)

  const details = isSpecialOffer ? (
    <Box style={{ width: '100%' }}>
      <Text fontSize='14px' fontWeight={500} color='#595769'>
        Total
      </Text>
      <Box
        sx={{
          display: 'flex',
          marginBlockEnd: selectedMethodPayment !== 'pix' && '16px',
        }}
      >
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row' }}>
          <PlanSmallLogoContainer>
            <Image
              aria-hidden='true'
              layout='fill'
              src={logo}
              alt='Logo do produto'
            />
          </PlanSmallLogoContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text variant='body-2' color='#757575'>
              De{' '}
              <Text
                as='span'
                style={{ textDecoration: 'line-through', fontWeight: 500 }}
                sx={{
                  fontSize: ['14px', '14px', '16px'],
                }}
              >
                {formatCurrency(chosenOffer?.displayPrice ?? 0)}
              </Text>
            </Text>

            {isInstallmentsEnabled && installment ? (
              <Text
                variant='headline-3'
                style={{ fontSize: '16px', lineHeight: '19.2px' }}
              >
                <Text
                  as='span'
                  style={{ fontWeight: 400, color: '#757575' }}
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  por
                </Text>{' '}
                {!isSubscriptionMonthly && `${installment?.installment}x de `}
                {formatCurrency(parseFloat(installment.price))}
                {displayOfferRecurrence}
              </Text>
            ) : (
              <Text variant='headline-3'>
                {formatCurrency(chosenOffer?.fullPrice ?? 0)}
                {displayOfferRecurrence}
              </Text>
            )}
          </Box>
        </Box>
        <Box sx={{ alignItems: 'right' }}>
          <Text
            variant='headline-3'
            style={{ color: '#24A148', fontSize: '16px', textAlign: 'right' }}
          >
            {offerDiscount}
            <span
              style={{
                display: 'block',
                fontSize: '10px',
                lineHeight: '16px',
                fontWeight: 400,
                color: '#757575',
              }}
            >
              *Apenas nessa página
            </span>
          </Text>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box>
      <Text variant='subheadline-3'>Total</Text>
      <Box
        sx={{
          display: 'flex',
          marginBlockEnd: selectedMethodPayment !== 'pix' && '16px',
        }}
      >
        <PlanSmallLogoContainer>
          <Image
            aria-hidden='true'
            layout='fill'
            src={logo}
            alt='Logo do produto'
          />
        </PlanSmallLogoContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text variant='body-2'>
            {chosenOffer?.name || product?.publicName || 'Queima Diária'}
          </Text>
          {isInstallmentsEnabled && installment ? (
            <Text variant='headline-3'>
              {!isSubscriptionMonthly && `${installment?.installment}x de `}
              {formatCurrency(parseFloat(installment.price))}
              {displayOfferRecurrence}
            </Text>
          ) : (
            <Text variant='headline-3'>
              {formatCurrency(chosenOffer?.fullPrice ?? 0)}
              {displayOfferRecurrence}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  )

  return details
}
const OfferDescription: FC = () => {
  const { chosenOffer } = useOffer()

  return (
    <Box
      sx={{
        margin: '16px 0 16px 0',
        textAlign: 'justify',
      }}
    >
      <Text variant='body-2'>{chosenOffer?.offerFormat?.description}</Text>
    </Box>
  )
}

const FormHelperText = styled(Text)`
  font-weight: 400;
  font-family: 'Graphik', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #b00020;
  margin-block-end: 24px;
`

const Stamp = styled.img`
  height: 50px;
`

export const PlanSmallLogoContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  margin-inline-end: 8px;
`
