import { Box, Text } from '@queimadiaria/ui'
import { useAtomValue } from 'jotai'
import { useFormContext } from 'react-hook-form'

import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'

import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { useDefaultCustomerData } from '../hooks/useDefaultCustomerData'
import { useUpdateLoginEmail } from '../hooks/useUpdateLoginEmail'
import { CheckoutFormPayload } from '../typings/checkoutFormTypings'

export const DefaultLoginEmail: React.FC = () => {
  const { getValues } = useFormContext<CheckoutFormPayload>()
  const isUpdateLoginEmail = useAtomValue(checkoutAtoms.isUpdateLoginEmail)
  const { handleIsUpdateLoginEmail } = useUpdateLoginEmail()
  const { isNewUser } = useCheckoutParamsFromUrl()

  useDefaultCustomerData()

  return (
    <Box
      padding={'0px 32px'}
      sx={{ display: !isUpdateLoginEmail ? 'block' : 'none' }}
    >
      <Text variant='body-1'>Você vai acessar a plataforma com o e-mail:</Text>

      <Box
        sx={{
          display: 'block',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Text variant='body-1'>
          <strong>{getValues('email')}</strong>
        </Text>
        <Box
          sx={{
            display: isNewUser ? 'flex' : 'none',
            cursor: 'pointer',
            justifyContent: 'start',
          }}
        >
          <Text
            onClick={() => handleIsUpdateLoginEmail()}
            variant='body-1'
            color='#FF4F00'
            css={{
              fontSize: '14px',
              lineHeight: '150%',
              marginInlineEnd: '8px',
              marginBlock: '4px',
              fontWeight: 500,
            }}
          >
            Alterar e-mail
          </Text>
          <img src='/assets/pencilOrange.svg' alt='Icone lapis' />
        </Box>
      </Box>
    </Box>
  )
}
