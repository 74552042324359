import { getStates } from '@brazilian-utils/brazilian-utils'

/**
 * Removes any dashes and trims whitespace from a given zip code.
 *
 * @param zipCode - The zip code to unmask.
 * @returns The unmasked zip code.
 */
export function zipCodeUnMask(zipCode: string) {
  return zipCode.replace('-', '').trim()
}

/**
 * Calculates the size of the zip code without mask characters.
 *
 * @param zipCode - The zip code with mask characters.
 * @returns The size of the zip code without mask characters.
 */
export function zipCodeSizeWithoutMask(zipCode: number) {
  return zipCodeUnMask(zipCode.toString()).length
}

/**
 * Checks if a given state code is valid.
 *
 * @param stateCode - The state code to validate.
 * @returns A boolean indicating whether the state code is valid or not.
 */
export function isValidState(stateCode: string) {
  const state = getStates().filter(({ code }) => code === stateCode)
  return state.length > 0
}
