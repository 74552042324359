import { TOrderBump } from 'src/features/orderBump/types/orderBump'
import { EntityId } from 'src/infra/typings/globalTypings'

export interface Offer {
  id: EntityId
  name: string
  description?: string
  fullPrice: number
  displayPrice?: number
  installments: number
  startDate?: string
  orderBump?: TOrderBump
  endDate?: string
  isDisplayCoupon?: boolean
  subscriptionPeriod: OfferSubscriptionPeriod
  productSellGroupId: EntityId | 'legacy-psg-id-placeholder'
  installmentsItems: Installment[]
  blockedInstallments: boolean
  offerFormat: OfferFormat | null
  disclaimer?: string
  isAvailableNupay?: boolean
}

export enum OfferSubscriptionPeriod {
  Yearly = 365,
}

export interface Installment {
  /** Numero de parcelas */
  installment: number
  price: string
}

export interface OfferFormat {
  id?: string
  createdAt?: string
  updatedAt?: string
  qtdPromotionalDays?: string
  promotionalAmount?: number
  renewProductSellGroup?: string
  offerId?: string
  description?: string
}
