import { getGoogleAnalyticsClientId } from 'src/features/analytics/utils/getGoogleAnalyticsClientId'
import { PaymentMethods } from 'src/features/checkout/typings/legacyCheckoutTypings'
import { MymetricData } from 'src/features/crossSellCheckout/types/mymetric'
import { Offer } from 'src/features/plan/offer/typings/offerTypings'
import { EntityId } from 'src/infra/typings/globalTypings'
import { offerPaymentPayload } from 'src/modules/payment/methods/utils/buildOfferPaymentPayload'
import { getOnlyNumbers } from 'src/utils/string'

import {
  CheckoutFormPayload,
  CheckoutFormPayloadWithBillet,
  CheckoutFormPayloadWithLegacyCreditCard,
} from '../../checkout/typings/checkoutFormTypings'
import { Subscription } from '../../checkout/typings/planTypings'
import { QueryParams } from '../../checkout/typings/queryParamsType'
import {
  isCheckoutFormPayloadWithBillet,
  isCheckoutFormPayloadWithLegacyCreditCard,
} from '../../checkout/utils/checkoutFormTypeGuards'
import {
  LegacyBilletOrderPayload,
  LegacyCreditCardOrderPayload,
  LegacyOrderPayload,
} from '../typings/pagarmeRequestTypings'
import { createPagarmeCardHash } from './cardHashPagarme'
import { parseBrazilianPhone } from './parseBrazilianPhone'

interface BaseCreditCardOrderParams {
  cardHash: string
  planId: number
  src: string | null
  subscription: Subscription
  formData: CheckoutFormPayloadWithLegacyCreditCard
  origin: string
  isNewCheckout: boolean
  isDirectCheckout: boolean
  offer?: Offer
  fpp?: string
  fpc?: string
  ip?: string
  mmTracker: string | null
  pageLocation: string
  gaSessionId?: string
  recaptchaToken: string | null
  sessionId: string
  recoveryId?: string
}

interface BaseBilletOrderParams {
  planId: number
  src: string | null
  formData: CheckoutFormPayloadWithBillet
  origin: string
  isNewCheckout: boolean
  isDirectCheckout: boolean
  mymetric: MymetricData
  offer?: Offer
  sessionId: string
  recoveryId?: string
}

const toPagarmeBilletOrder = async (
  productId: EntityId,
  queryParams: QueryParams,
  dataForm: CheckoutFormPayload,
  isNewCheckout: boolean,
  isDirectCheckout: boolean,
  mymetric: MymetricData,
  sessionId: string,
  offer?: Offer
): Promise<LegacyOrderPayload> => {
  if (!isCheckoutFormPayloadWithBillet(dataForm))
    throw new Error('Invalid payload')
  const { src, qp_origin, recoveryId } = queryParams

  const checkoutParams = {
    src: src ?? null,
    planId: parseFloat(productId.toString()),
    formData: dataForm,
    origin: qp_origin ?? 'qp-desktop',
    isNewCheckout,
    isDirectCheckout,
    mymetric,
    offer,
    sessionId,
    recoveryId,
  }

  return toBilletOrder(checkoutParams)
}

const toBilletOrder = async ({
  planId,
  src,
  formData,
  origin,
  isNewCheckout,
  isDirectCheckout,
  mymetric,
  sessionId,
  offer,
  recoveryId,
}: BaseBilletOrderParams): Promise<LegacyBilletOrderPayload> => {
  const phone = parseBrazilianPhone(formData.phone)
  const offerId = parseInt(offer ? offer.id.toString() : '')

  const offerPayload = offerPaymentPayload(offer, formData)

  return {
    clientId: await getGoogleAnalyticsClientId(),
    paymentMethod: PaymentMethods.Billet,
    planId: planId,
    name: formData.name,
    installments: 1,
    email: formData.email,
    doc: getOnlyNumbers(formData.document),
    subscription: Subscription.YEARLY,
    phone: {
      ddd: phone.ddd,
      number: phone.phone,
    },
    metadata: { origin: origin },
    address: {
      zipCode: formData.zipCode,
      street: formData.street,
      number: formData.noHasNumber === 1 ? '0' : formData.number,
      complement: formData.complement,
      district: formData.district,
      city: formData.city,
      state: formData.state,
    },
    src,
    isNewCheckout,
    isDirectCheckout,
    fpp: mymetric.fpp,
    fpc: mymetric.fpc,
    ip: mymetric.ip,
    pageLocation: mymetric.pageLocation,
    gaSessionId: mymetric.gaSessionId,
    mmTracker: mymetric.mmTracker,
    recapchaToken: formData.recaptchaToken,
    offerId,
    ...offerPayload,
    sessionId,
    recoveryId,
    fullOrigin: window.location.href,
  }
}

async function toPagarmeCreditCardOrderBody(
  productId: EntityId,
  payload: CheckoutFormPayload,
  queryParams: QueryParams,
  isNewCheckout: boolean,
  isDirectCheckout: boolean,
  mymetricData: MymetricData,
  sessionId: string,
  offer?: Offer
) {
  if (!isCheckoutFormPayloadWithLegacyCreditCard(payload))
    throw new Error('Invalid payload')

  const { src, qp_origin, recoveryId } = queryParams
  const cardHash = await createPagarmeCardHash(payload)
  const checkoutParams = {
    src: src ?? null,
    origin: qp_origin ?? 'qp-desktop',
    paymentMethod: 'credit_card',
    cardHash: cardHash,
    planId: parseInt(productId.toString()),
    subscription: Subscription.YEARLY,
    formData: payload,
    offer,
    isNewCheckout,
    isDirectCheckout,
    fpp: mymetricData.fpp,
    fpc: mymetricData.fpc,
    ip: mymetricData.ip,
    mmTracker: mymetricData.mmTracker,
    pageLocation: mymetricData.pageLocation,
    gaSessionId: mymetricData.gaSessionId,
    recaptchaToken: payload.recaptchaToken,
    sessionId,
    recoveryId,
  }

  return toCreditCardOrder(checkoutParams)
}
const toCreditCardOrder = async ({
  cardHash,
  planId,
  src,
  origin,
  subscription,
  formData,
  offer,
  isNewCheckout,
  isDirectCheckout,
  fpp,
  fpc,
  ip,
  mmTracker,
  pageLocation,
  gaSessionId,
  recaptchaToken,
  sessionId,
  recoveryId,
}: BaseCreditCardOrderParams): Promise<LegacyCreditCardOrderPayload> => {
  const phone = parseBrazilianPhone(formData.phone)
  const offerId = parseInt(offer ? offer.id.toString() : '')

  const offerPayload = offerPaymentPayload(offer, formData)

  return {
    clientId: await getGoogleAnalyticsClientId(),
    name: formData.name,
    email: formData.email,
    doc: getOnlyNumbers(formData.document),
    phone: {
      ddd: phone.ddd,
      number: phone.phone,
    },
    paymentMethod: 'credit_card',
    card: cardHash,
    planId: planId,
    installments: formData.installments ?? offer?.installments,
    src: src,
    subscription: subscription,
    metadata: { origin: origin },
    isNewCheckout,
    isDirectCheckout,
    fpp,
    fpc,
    ip,
    mmTracker,
    pageLocation,
    gaSessionId,
    recapchaToken: recaptchaToken,
    offerId,
    ...offerPayload,
    sessionId,
    recoveryId,
    fullOrigin: window.location.href,
  }
}

export const pagarmeRequestBodyFactories = {
  toCreditCardOrder: toPagarmeCreditCardOrderBody,
  toBilletOrder: toPagarmeBilletOrder,
}
