import * as Sentry from '@sentry/nextjs'
import { useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai'

import { useTryToCaptureDataToMymetric } from 'src/features/analytics/hooks/useTryToCaptureDataToMymetric'
import { getGoogleAnalyticsClientId } from 'src/features/analytics/utils/getGoogleAnalyticsClientId'
import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'
import { ToastCustom } from 'src/features/checkout/components/Toast'
import {
  CheckoutFormPayload,
  CheckoutFormPayloadWithNupay,
} from 'src/features/checkout/typings/checkoutFormTypings'
import { isCheckoutFormPayloadWithNupay } from 'src/features/checkout/utils/checkoutFormTypeGuards'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useCheckoutDetails } from 'src/features/plan/checkout/hooks/useCheckoutDetails'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'
import { SerializedApiError } from 'src/infra/typings/globalTypings'
import { offerPaymentPayload } from 'src/modules/payment/methods/utils/buildOfferPaymentPayload'
import { isSerializedApiError } from 'src/utils/isSerializedApiError'
import { getOnlyNumbers } from 'src/utils/string'

import { paymentsApi } from '../apis/paymentApi'
import { paymentAtoms } from '../atoms/paymentAtoms'
import { TryRequestNupayPaymentPayload } from '../typings/paymentTypings'
import { addMarketingParamsToRedirectUrl } from '../utils/addMarketingParamsToRedirectUrl'
import { checkIsSelfIndication } from '../utils/checkIsSelfIndication'
import { parseBrazilianPhone } from '../utils/parseBrazilianPhone'

export function useTryToRequestNupayPayment(
  onPurchaseSuccess?: (payload: CheckoutFormPayload) => void
) {
  const queryParams = useCheckoutParamsFromUrl()

  const [, setIsCreatingOrder] = useAtom(paymentAtoms.isCreatingOrder)
  const [, isRedirectingAfterSuccess] = useAtom(
    paymentAtoms.isRedirectingAfterSuccess
  )

  const [, setHasErrorCreatingOrder] = useAtom(
    paymentAtoms.hasErrorCreatingOrder
  )

  const [, setCreatingOrderErrorDetails] = useAtom(
    paymentAtoms.creatingOrderErrorDetails
  )

  const [, setPaymentCheckoutToFallback] = useAtom(
    checkoutAtoms.paymentCheckoutInFallback
  )

  const { qp_origin, recoveryId } = useCheckoutParamsFromUrl()
  const { offer } = useOffer()
  const { product } = useProduct()
  const { checkoutDetails } = useCheckoutDetails()

  const isDirectCheckout = useAtomValue(checkoutAtoms.isDirectCheckout)
  const recaptchaRef = useAtomValue(checkoutAtoms.recaptchaRef)
  const sessionId = useAtomValue(checkoutAtoms.sessionId)

  const { handleTryToCapturaDataToMymetric } = useTryToCaptureDataToMymetric()

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (formPayload: CheckoutFormPayloadWithNupay) => {
      Sentry.setUser({
        name: formPayload.name,
        email: formPayload.email,
        phone: parseBrazilianPhone(formPayload.phone),
      })
      const mymetricData = await handleTryToCapturaDataToMymetric()

      if (isCheckoutFormPayloadWithNupay(formPayload) && offer) {
        const {
          fpp,
          fpc,
          ip,
          gaSessionId,
          pageLocation,
          distinctId,
          mmTracker,
        } = mymetricData

        const offerId = parseInt(offer.id.toString())

        const offerPayload = offerPaymentPayload(offer, formPayload)
        const clientId = await getGoogleAnalyticsClientId()

        const payload: TryRequestNupayPaymentPayload = {
          name: formPayload.name,
          email: formPayload.email,
          phone: parseBrazilianPhone(formPayload.phone),
          document: getOnlyNumbers(formPayload.document),
          origin: qp_origin ?? 'web',
          fundingSource: formPayload.nupay?.fundingSource || 'credit',
          installments: formPayload.nupay?.installment || null,
          isFallback: formPayload.nupay?.isFallback || false,
          productSellGroupId: parseInt(offer.productSellGroupId.toString()),
          offerId,
          ...offerPayload,
          customerId: null,
          gaClientId: clientId,
          src: queryParams.src || 'null',
          isDirectCheckout,
          fpp,
          fpc,
          ip,
          paymentMethod: 'nupay',
          mmTracker,
          pageLocation,
          gaSessionId,
          recapchaToken: formPayload.recaptchaToken,
          sessionId,
          recoveryId,
          distinctId,
          fullOrigin: window.location.href,
        }
        return paymentsApi.tryToCreateNupayPayment(payload)
      }
      throw new Error('invalid payload')
    },
    onMutate: () => {
      setIsCreatingOrder(true)
    },
    onSuccess: (response, payload) => {
      if (!product || !offer) return

      isRedirectingAfterSuccess(true)

      onPurchaseSuccess && onPurchaseSuccess(payload)

      const isFromAndroidApp = qp_origin?.includes('android') ?? false
      // Open deeplink back to the mobile app
      if (isFromAndroidApp) {
        setTimeout(() => {
          window.location.href = 'queimadiaria://app/success-checkout'
        }, 300)
      }

      const redirectUrl = addMarketingParamsToRedirectUrl(
        response.checkout.successCardRoute,
        product.id.toString(),
        product.publicName,
        offer.fullPrice
      )

      setTimeout(() => {
        window.location.href = isFromAndroidApp
          ? checkoutDetails?.origin ?? ''
          : redirectUrl
      }, 1300)
    },
    onError: (error: SerializedApiError) => {
      recaptchaRef.current?.reset()
      setPaymentCheckoutToFallback(true)

      if (error && error.status !== 403) {
        setHasErrorCreatingOrder(true)
        setCreatingOrderErrorDetails(
          isSerializedApiError(error) ? error : undefined
        )

        return
      }
      checkIsSelfIndication(error) && setCreatingOrderErrorDetails(error)
      ToastCustom({
        type: 'error',
        message: 'Por favor, verifique o reCAPTCHA novamente!',
      })
    },
    onSettled: () => {
      if (!product || !offer) return
      setIsCreatingOrder(false)
    },
  })

  return { tryToRequestNupayPayment: mutate, isLoading: isPending, isError }
}
