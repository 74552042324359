export function cleanupPhoneNumber(rawPhone?: string) {
  if (!rawPhone) return ''

  const matched = rawPhone.trim().match(/^(\+?\d{2})?(\d{2}\d?\d{4}\d{4}$)/)

  if (!matched) return ''

  const [, , phone] = matched

  return phone
}
