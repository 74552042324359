import { useCallback } from 'react'

import { Subscription } from 'src/features/checkout/typings/planTypings'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useCheckoutDetails } from 'src/features/plan/checkout/hooks/useCheckoutDetails'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'

import { fbq } from '../utils/fbq'
import { toJoinAliasSubscription } from '../utils/pixelHelpers'

export function useAddedContactInfoPixel() {
  const { checkoutDetails } = useCheckoutDetails()
  const { product } = useProduct()
  const queryParams = useCheckoutParamsFromUrl()

  const { src } = queryParams

  const onEvent = useCallback(() => {
    if (!checkoutDetails?.facebookPixels || !product) return

    const category = toJoinAliasSubscription(product.alias, Subscription.YEARLY)

    const event = {
      category: category,
      plan: {
        id: product.id.toString(),
        name: product.publicName,
      },
      src: src ? { src } : {},
    }
    fbq('trackCustom', 'AddContactInfo', event)
  }, [checkoutDetails, product, src])

  return onEvent
}
