import styled from '@emotion/styled'
import { Box, Flex, Spinner, Text } from '@queimadiaria/ui'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai'
import dynamic from 'next/dynamic'
import { FC, FormEvent, memo, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { CupomDigitalCheckout } from 'src/features/CupomDigitalCheckout/components/CupomDigitalCheckout'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { OrderBumpCheckout } from 'src/features/orderBump/components/OrderBumpCheckout'
import { useTryToCreateOrder } from 'src/features/payment/hooks/useTryToCreateOrder'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'
import { usePaymentDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/modules/payment/usePaymentDigitalCheckoutContext'

import { CreditCardLoadingState } from '../../adyen/components/CreditCardLoadingState'
import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { PixPaymentAtoms } from '../atoms/PixPaymentAtoms'
import { useActiveCampaignLead } from '../hooks/useActiveCampaignLead'
import { useServerlessLeads } from '../hooks/useServerlessLeads'
import { useWatchBilletParams } from '../hooks/useWatchBilletParams'
import { useWatchSalesRecoveryParams } from '../hooks/useWatchSalesRecoveryParams'
import { CheckoutFormPayloadWithNupay } from '../typings/checkoutFormTypings'
import { PaymentMethods } from '../typings/legacyCheckoutTypings'
import { AnotherFormOfPayment } from './AnotherFormOfPayment'
import { BilletPaymentForm } from './BilletPaymentForm'
import { CheckoutSubmitDetails } from './CheckoutSubmitDetails'
import DataPersonalFormContainer from './DataPersonalForm'
import { DefaultLoginEmail } from './DefaultLoginEmail'
import { DefaultParamEmail } from './DefaultParamEmail'
import { InstallmentWatcher } from './InstallmentsWatcher'
import { PaymentOptions } from './PaymentOptions'
import { PixPayment } from './PixPayment'
import { PixPaymentErrorMessage } from './PixPaymentErrorMessage'
import { PixPaymentNewCheckout } from './PixPaymentNewCheckout'
import { SubHeader } from './SubHeader'
import { TermsOfUse } from './TermsOfUse'

interface CheckoutFormProps {
  handleFormSubmit: (e: FormEvent<HTMLFormElement>) => void
  paymentMethod: PaymentMethods
  isErrorPaymentPix: boolean
  markBump: boolean
}

export const CheckoutFormContainer = () => {
  const { handleSubmit, watch } = useFormContext<CheckoutFormPayloadWithNupay>()

  const { tryToCreateOrder } = useTryToCreateOrder()

  const [isErrorPaymentPix] = useAtom(PixPaymentAtoms.isErrorPaymentPix)
  const [, setPaymentMethodsAtoms] = useAtom(checkoutAtoms.paymentMethodsAtoms)
  const { markBump } = useCheckoutParamsFromUrl()

  const [paymentMethod] = watch(['paymentMethod'])

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit(tryToCreateOrder, console.error)(e)
  }

  useWatchBilletParams()
  useWatchSalesRecoveryParams()
  useActiveCampaignLead()
  useServerlessLeads()

  useEffect(() => {
    setPaymentMethodsAtoms(paymentMethod)
  }, [paymentMethod])

  return (
    <>
      <InstallmentWatcher />
      <CheckoutForm
        handleFormSubmit={handleFormSubmit}
        paymentMethod={paymentMethod}
        isErrorPaymentPix={isErrorPaymentPix}
        markBump={markBump}
      />
    </>
  )
}

export const CheckoutForm: FC<CheckoutFormProps> = memo(
  ({ handleFormSubmit, paymentMethod, isErrorPaymentPix, markBump }) => {
    const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
    const isDirectCheckout = useAtomValue(checkoutAtoms.isDirectCheckout)
    const hasParamEmail = useAtomValue(checkoutAtoms.hasParamEmail)
    const { offer } = useOffer()
    const { product } = useProduct()
    const { selectedMethodPayment, paramsConfigPayment, PaymentComponent } =
      usePaymentDigitalCheckoutContext()

    const isShowOrderBump = !!offer?.orderBump && offer.orderBump.isActive

    if (!isNewCheckout) {
      return (
        <Flex
          sx={{
            margin: '0 auto',
            flexDirection: 'column',
            boxSizing: 'border-box',
            height: '100%',
            width: '100%',
          }}
        >
          <form
            css={{ display: 'flex', flexDirection: 'column' }}
            onSubmit={handleFormSubmit}
            onError={console.error}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                maxWidth: '100%',
                width: '100%',
              }}
            >
              <SubHeader />
              <DataPersonalFormContainer />
              <PaymentOptions />
              {selectedMethodPayment === 'billet' && <BilletPaymentForm />}
              {selectedMethodPayment === 'creditCard' &&
                paramsConfigPayment?.methods?.creditCardConfig?.provider ===
                  'adyen' && <CreditCardFormLazy />}
              {selectedMethodPayment === 'nupay' && PaymentComponent}

              {selectedMethodPayment === 'creditCard' &&
                paramsConfigPayment?.methods?.creditCardConfig?.provider ===
                  'legacy' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flex: '1',
                    }}
                  >
                    <Flex
                      sx={{
                        flexDirection: 'column',
                        margin: '16px 0 16px 0',
                        width: ['100%', '100%', '50%', '50%'],
                      }}
                    >
                      <Box>
                        <Text variant='subheadline-2'>
                          Pagamento com cartão de crédito.
                        </Text>
                        <LegacyCreditCardFormLazy />
                      </Box>
                    </Flex>
                    <Box
                      sx={{
                        marginLeft: '2vw',
                        display: ['none', 'none', 'block', 'block'],
                        visibility: ['hidden', 'hidden', 'visible', 'visible'],
                      }}
                    >
                      <CreditCardThumbnailLazy />
                    </Box>
                  </Box>
                )}
              {offer?.isDisplayCoupon && !!product ? (
                <CupomDigitalCheckout
                  offerId={Number(offer.id)}
                  productSellGroupId={Number(product.id)}
                />
              ) : (
                isShowOrderBump && (
                  <OrderBumpCheckout offer={offer!} markBump={markBump} />
                )
              )}
              <CheckoutSubmitDetails />
              {selectedMethodPayment === 'pix' && !isErrorPaymentPix && (
                <PixPayment />
              )}
              {selectedMethodPayment === 'pix' && isErrorPaymentPix && (
                <PixPaymentErrorMessage />
              )}
              <TermsOfUse />
            </Flex>
          </form>
        </Flex>
      )
    }

    return (
      <Flex
        sx={{
          margin: '0 auto',
          flexDirection: 'column',
          boxSizing: 'border-box',
          height: '100%',
          width: '100%',
        }}
      >
        <form
          css={{ display: 'flex', flexDirection: 'column' }}
          onSubmit={handleFormSubmit}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              maxWidth: '100%',
              width: '100%',
            }}
          >
            <SubHeader />
            {!isDirectCheckout && <DefaultLoginEmail />}
            {hasParamEmail && <DefaultParamEmail />}
            <DataPersonalFormContainer />

            {(paymentMethod === PaymentMethods.Billet ||
              paymentMethod === PaymentMethods.Pix) && <AnotherFormOfPayment />}
            {paymentMethod === PaymentMethods.Billet && <PaymentOptions />}

            {paymentMethod === PaymentMethods.Billet && <BilletPaymentForm />}
            {paymentMethod === PaymentMethods.CreditCard && (
              <CreditCardFormLazy />
            )}
            {paymentMethod === PaymentMethods.LegacyCreditCard && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flex: '1',
                }}
              >
                <Flex
                  sx={{
                    flexDirection: 'column',
                    margin: '16px 0 16px 0',
                    width: ['100%', '100%', '50%', '50%'],
                  }}
                >
                  <Box>
                    <Text variant='subheadline-2'>
                      Pagamento com cartão de crédito.
                    </Text>
                    <LegacyCreditCardFormLazy />
                  </Box>
                </Flex>
                <Box
                  sx={{
                    marginLeft: '2vw',
                    display: ['none', 'none', 'block', 'block'],
                    visibility: ['hidden', 'hidden', 'visible', 'visible'],
                  }}
                >
                  <CreditCardThumbnailLazy />
                </Box>
              </Box>
            )}
            <CheckoutSubmitDetails />
            {paymentMethod === PaymentMethods.Pix && !isErrorPaymentPix && (
              <PixPaymentNewCheckout />
            )}

            {paymentMethod === PaymentMethods.Pix && isErrorPaymentPix && (
              <PixPaymentErrorMessage />
            )}
          </Flex>
        </form>
      </Flex>
    )
  }
)
const CreditCardThumbnailLazy = dynamic(() => import('./CreditCardThumbnail'), {
  ssr: false,
  loading: () => (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        fontSize: 'xlarge',
        width: 279,
        aspectRatio: '1.5858',
        backgroundColor: '#F4F4F4',
        borderRadius: 13,
      }}
    />
  ),
})

const CreditCardFormLazy = dynamic(
  () => import('../../adyen/components/CreditCardForm'),
  {
    ssr: false,
    loading: () => <CreditCardLoadingState />,
  }
)

const LegacyCreditCardFormLazy = dynamic(
  () => import('./LegacyCreditCardForm'),
  {
    ssr: false,
    loading: () => (
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          fontSize: 'xlarge',
          height: 288,
        }}
      >
        <Spinner />
      </Flex>
    ),
  }
)

export const Redirect = styled.a`
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #6e6c7c;
`
