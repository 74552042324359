import { useAtomValue } from 'jotai'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { CheckoutFormPayload } from '../typings/checkoutFormTypings'

export function useDefaultCustomerData() {
  const { setValue } = useFormContext<CheckoutFormPayload>()

  const customerData = useAtomValue(checkoutAtoms.customerData)

  useEffect(() => {
    const { email, phone, name } = customerData
    setValue('email', email)
    setValue('phone', phone)
    setValue('name', name)
  }, [customerData])
}
