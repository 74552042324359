import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { PixPaymentAtoms } from 'src/features/checkout/atoms/PixPaymentAtoms'
import { ToastCustom } from 'src/features/checkout/components/Toast'
import { CheckoutFormPayload } from 'src/features/checkout/typings/checkoutFormTypings'
import { checkoutValidationSchema } from 'src/features/checkout/utils/checkoutValidationSchema'
import { usePixCountdownAtoms } from 'src/features/pixCountdown/atoms/usePixCountdownAtoms'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'

import { paymentAtoms } from '../atoms/paymentAtoms'
import { ResponseUseTryToCreateOrderPix } from '../typings/useTryToCreateOrderPixTypings'
import { useTryToRequestPixPayment } from './useTryToRequestPixPayment'

export function useTryToCreateOrderPix(): ResponseUseTryToCreateOrderPix {
  const [pixPaymentDetails, setPixPaymentDetails] = useAtom(
    paymentAtoms.pixPaymentDetails
  )
  const [, setIsCopyQrCode] = useAtom(PixPaymentAtoms.isCopyQrCode)
  const [isNewRequestQrCode, setIsNewRequestQrCode] = useAtom(
    usePixCountdownAtoms.isNewRequestQrCode
  )
  const [isFormDataValid, setIsFormDataValid] = useAtom(
    PixPaymentAtoms.isFormDataValid
  )
  const { getValues, trigger, formState } =
    useFormContext<CheckoutFormPayload>()
  const { isValid } = formState

  const { tryRequestLegacyPixPayment, isLoading, data } =
    useTryToRequestPixPayment()

  const { chosenOffer } = useOffer()

  const handleQrCodeRequest = async () => {
    if (isNewRequestQrCode) {
      await tryRequestLegacyPixPayment(getValues())
      setIsCopyQrCode(false)
    }
  }

  const handleFormValidationChecking = async () => {
    trigger()
    const isValidByYup = await checkoutValidationSchema.isValid(getValues())

    if (isValidByYup) {
      handleStatusChangeToTrueAndRequestQRCode()
    } else if (!isValidByYup) {
      handleStatusChangeToFalseAndTriggerErrorToast()
    }
  }

  const handleStatusChangeToTrueAndRequestQRCode = () => {
    setIsFormDataValid(true)
    handleQrCodeRequest()
  }

  const handleStatusChangeToFalseAndTriggerErrorToast = () => {
    ToastCustom({
      type: 'error',
      message: 'Ainda existem campos não preenchidos',
    })
    setIsFormDataValid(false)
  }

  useEffect(() => {
    const isEmptyPixData = data === undefined

    if (!isEmptyPixData) {
      setPixPaymentDetails({
        status: data.status,
        expiresAt: new Date(data.expiresAt),
        qrCode: data.qrCode,
      })
    }
  }, [data, isNewRequestQrCode])

  useEffect(() => {
    handleFormValidationChecking()
  }, [isValid, isNewRequestQrCode])

  useEffect(() => {
    if (!!data) {
      setIsNewRequestQrCode(true)
    }
  }, [chosenOffer])

  return {
    isFormDataValid,
    isLoading,
    pixPaymentDetails,
    tryRequestLegacyPixPayment,
  }
}
