/* eslint-disable @typescript-eslint/no-explicit-any */
import { SerializedApiError } from 'src/infra/typings/globalTypings'

export const fromErrorChangeUserEmailToSerializedApiError = ({
  error,
}: any) => {
  const serializedError: SerializedApiError = {
    isSerializedApiError: true,
    statusCode: error.statusCode,
    code: error?.data?.operationStatus || error?.data?.status || error?.code,
    message: error.message,
  }

  return serializedError
}
