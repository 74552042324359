import { Address } from 'src/features/checkout/typings/checkoutFormTypings'

import { Subscription } from '../../checkout/typings/planTypings'

export interface LegacyOrderPayload {
  name: string
  email: string
  paymentMethod: string
  planId: number
  installments: number
  subscription: Subscription
  clientId: string
  doc: string
  phone: {
    ddd: string
    number: string
  }
  metadata: { origin: string }
  src: string | null
  isNewCheckout: boolean
  isDirectCheckout: boolean
  fpp?: string
  fpc?: string
  ip?: string
  mmTracker: string | null
  gaSessionId?: string
  pageLocation: string
  recapchaToken: string | null
  offerId: number | null
  sessionId: string
  recoveryId?: string
  fullOrigin: string
}

export enum PaymentStatus {
  Processing = 'processing',
  Authorized = 'authorized',
  Refunded = 'refunded',
  PendingRefund = 'pending_refund',
  Paid = 'paid',
  Refused = 'refused',
  WaitingPayment = 'waiting_payment',
}

export interface LegacyCreditCardOrderPayload extends LegacyOrderPayload {
  card: string
  src: string | null
}

export interface LegacyBilletOrderPayload extends LegacyOrderPayload {
  address: Address
}
