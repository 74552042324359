import { Box, Flex, Text } from '@queimadiaria/ui'
import { useAtomValue } from 'jotai'
import { FC } from 'react'

import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { AddressFormContainer } from './AddressForm'

export const BilletPaymentForm: FC = () => {
  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)

  if (!isNewCheckout) {
    return (
      <Flex sx={{ flexDirection: 'column' }}>
        <Box>
          <Text variant='subheadline-2'> Pagamento com boleto.</Text>
          <AddressFormContainer />
          <Box>
            <Text variant='subheadline-3'>Atente-se aos detalhes:</Text>
            <Text as='ul' variant='body-1' color='#595769'>
              <li>
                Pagamentos por boleto são somente à vista e levam até{' '}
                <span
                  css={{
                    fontWeight: 500,
                  }}
                >
                  72h
                </span>{' '}
                úteis para serem compensados.
              </li>
              <li>
                Se o boleto não for pago até a data de vencimento, seu pedido
                será cancelado. O pagamento pode ser feito em qualquer banco ou
                casa lotérica até o vencimento.
              </li>
              <li
                css={{
                  fontWeight: 500,
                }}
              >
                Depois do pagamento, fique atento ao seu e-mail, vamos enviar
                seu acesso à Queima Diária.
              </li>
            </Text>
          </Box>
        </Box>
      </Flex>
    )
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        padding: '0px 32px 24px 32px',
        background: '#FAFAFA',
      }}
    >
      <Box>
        <Text
          variant='subheadline-2'
          css={{
            marginBlockEnd: '16px',
          }}
        >
          Pagamento com boleto
        </Text>
        <AddressFormContainer />
        <Box>
          <Text variant='subheadline-3'>Atente-se aos detalhes:</Text>
          <Text as='ul' variant='body-1' color='#595769'>
            <li>
              Pagamentos por boleto são somente à vista e levam até{' '}
              <span
                css={{
                  fontWeight: 500,
                }}
              >
                72h
              </span>{' '}
              úteis para serem compensados.
            </li>
            <li>
              Se o boleto não for pago até a data de vencimento, seu pedido será
              cancelado. O pagamento pode ser feito em qualquer banco ou casa
              lotérica até o vencimento.
            </li>
            <li
              css={{
                fontWeight: 500,
              }}
            >
              Depois do pagamento, fique atento ao seu e-mail, vamos enviar seu
              acesso à Queima Diária.
            </li>
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}
