import { TOrderBump } from 'src/features/orderBump/types/orderBump'
import { Offer } from 'src/features/plan/offer/typings/offerTypings'
import { Coupon } from 'src/modules/coupon/types/coupon.types'

export interface OfferPaymentPayload {
  offerId: number
  oldOfferId?: number
  orderBumpId?: number
  couponId?: number
}

type OfferPaymentPayloadBuilder = (
  offerPayment: Partial<OfferPaymentPayload>
) => Partial<OfferPaymentPayload>

function buildOfferPaymentPayload(
  ...builders: Array<OfferPaymentPayloadBuilder>
): OfferPaymentPayload {
  const offerPaymentPayload = builders.reduce(
    (acc, builder) => builder(acc),
    {} as Partial<OfferPaymentPayload>
  )

  if (!offerPaymentPayload.offerId) {
    throw new Error('Cant contstruct offerPaymentPayload without offerId')
  }

  return offerPaymentPayload as OfferPaymentPayload
}

const makeBaseOffer =
  (offerId: number) =>
  (
    offerPayment: Partial<OfferPaymentPayload>
  ): Partial<OfferPaymentPayload> => ({
    ...offerPayment,
    offerId,
  })

const applyCouponInOfferPaymentPayload =
  (couponId: number, couponOfferId: number, offerId: number) =>
  (
    offerPayment: Partial<OfferPaymentPayload>
  ): Partial<OfferPaymentPayload> => ({
    ...offerPayment,
    offerId: couponOfferId,
    oldOfferId: offerId,
    couponId,
  })

const applySelectedOrderBumpInOfferPaymentPayload =
  (orderBumpId: number, orderBumpOfferId: number, offerId: number) =>
  (
    offerPayment: Partial<OfferPaymentPayload>
  ): Partial<OfferPaymentPayload> => ({
    ...offerPayment,
    oldOfferId: offerId,
    offerId: orderBumpOfferId,
    orderBumpId,
  })

const applyUnselectedOrderBumpInOfferPaymentPayload =
  (orderBumpId: number) =>
  (
    offerPayment: Partial<OfferPaymentPayload>
  ): Partial<OfferPaymentPayload> => ({
    ...offerPayment,
    orderBumpId,
  })

export const offerPaymentPayload = (
  offer: Offer | undefined,
  payload: {
    coupon?: Coupon | null | undefined
    orderBump?: TOrderBump | null | undefined
  }
) => {
  if (!offer) return {}

  const offerId = Number(offer.id)
  const base = makeBaseOffer(offerId)

  const isOrderBumpActive =
    offer &&
    offer.orderBump &&
    offer.orderBump.isActive &&
    !offer.isDisplayCoupon

  const isCouponActive = !!offer?.isDisplayCoupon

  const coupon = isCouponActive ? payload.coupon : undefined
  const orderBumpSelected = isOrderBumpActive ? payload.orderBump : undefined

  if (coupon) {
    return buildOfferPaymentPayload(
      base,
      applyCouponInOfferPaymentPayload(
        coupon.id,
        Number(coupon.destinationOffer.id),
        offerId
      )
    )
  }

  if (isOrderBumpActive) {
    return buildOfferPaymentPayload(
      base,
      orderBumpSelected
        ? applySelectedOrderBumpInOfferPaymentPayload(
            orderBumpSelected.id,
            orderBumpSelected.destinationOfferId,
            offerId
          )
        : applyUnselectedOrderBumpInOfferPaymentPayload(offer.orderBump!.id)
    )
  }

  return buildOfferPaymentPayload(base)
}
