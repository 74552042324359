import { useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai'

import { useTryToCaptureDataToMymetric } from 'src/features/analytics/hooks/useTryToCaptureDataToMymetric'
import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'

import {
  CheckoutFormPayload,
  CheckoutFormPayloadWithBillet,
} from '../../checkout/typings/checkoutFormTypings'
import { isCheckoutFormPayloadWithBillet } from '../../checkout/utils/checkoutFormTypeGuards'
import { useCheckoutParamsFromUrl } from '../../layout/hooks/useCheckoutParamsFromUrl'
import { legacyPaymentApi } from '../apis/legacyPaymentApi'
import { paymentAtoms } from '../atoms/paymentAtoms'
import { PaymentStatus } from '../typings/pagarmeRequestTypings'
import { addParamsToRedirectUrlBillet } from '../utils/addMarketingParamsToRedirectUrl'
import { pagarmeRequestBodyFactories } from '../utils/pagarmeRequestBodyFactories'
import {
  generateJwtBilletOrder,
  setBilletOrder,
  setMarketingBillet,
} from './useTryToCreateOrderBillet'

export function useTryRequestLegacyBilletPayment(
  onPurchaseSuccess?: (payload: CheckoutFormPayload) => void
) {
  const queryParams = useCheckoutParamsFromUrl()
  const { product } = useProduct()
  const { offer } = useOffer()

  const [, setIsCreatingOrder] = useAtom(paymentAtoms.isCreatingOrder)
  const [, isRedirectingAfterSuccess] = useAtom(
    paymentAtoms.isRedirectingAfterSuccess
  )
  const [, setHasErrorCreatingOrder] = useAtom(
    paymentAtoms.hasErrorCreatingOrder
  )

  const sessionId = useAtomValue(checkoutAtoms.sessionId)
  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
  const isDirectCheckout = useAtomValue(checkoutAtoms.isDirectCheckout)

  const { handleTryToCapturaDataToMymetric } = useTryToCaptureDataToMymetric()

  let user: CheckoutFormPayloadWithBillet

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (payload: CheckoutFormPayload) => {
      if (!isCheckoutFormPayloadWithBillet(payload) || !product)
        throw new Error('invalid payload')

      const mymetric = await handleTryToCapturaDataToMymetric()

      const body = await pagarmeRequestBodyFactories.toBilletOrder(
        product.fullOldPlanId,
        queryParams,
        payload,
        isNewCheckout,
        isDirectCheckout,
        mymetric,
        sessionId,
        offer
      )

      user = payload

      return await legacyPaymentApi.tryToCreateOrder(body)
    },
    onMutate: () => {
      setIsCreatingOrder(true)
    },
    onSuccess: async (response, payload) => {
      if (!offer || !product) return

      onPurchaseSuccess && onPurchaseSuccess(payload)
      setIsCreatingOrder(false)

      if (response.status === PaymentStatus.WaitingPayment) {
        isRedirectingAfterSuccess(true)

        const orderBillet = setBilletOrder(offer, response, user, product)
        const redirectUrl = addParamsToRedirectUrlBillet(
          '/billet',
          setMarketingBillet(product, offer),
          generateJwtBilletOrder(orderBillet)
        )
        setTimeout(() => {
          window.location.href = redirectUrl
        }, 1000)
      }
    },
    onError: () => {
      setHasErrorCreatingOrder(true)
      setIsCreatingOrder(false)
    },
  })

  return {
    tryRequestLegacyBilletPayment: mutate,
    isLoading: isPending,
    isError,
  }
}
