import styled from '@emotion/styled'
import { Box, Flex, Text } from '@queimadiaria/ui'
import React from 'react'

export const PixPaymentErrorMessage: React.FC = () => {
  return (
    <Box
      padding={24}
      sx={{
        border: '1px solid #B00020',
        borderRadius: '8px',
        marginBottom: '24px',
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <img src='/assets/errorOutline.svg' alt='error icon' />
        <Title variant='subheadline-2' color={'feedback.negative'}>
          Erro no pagamento Pix
        </Title>
      </Flex>
      <Text variant='body-1' color={'feedback.negative'}>
        Escolha outro meio de pagamento ou tente novamente mais tarde.
      </Text>
    </Box>
  )
}

const Title = styled(Text)`
  font-weight: 600;
  line-height: 32px;
  margin-left: 16px;
`
