import { usePurchasedPixel } from 'src/features/analytics/hooks/usePurchasedPixel'
import { CheckoutFormPayload } from 'src/features/checkout/typings/checkoutFormTypings'
import {
  isCheckoutFormPayloadWithBillet,
  isCheckoutFormPayloadWithNupay,
} from 'src/features/checkout/utils/checkoutFormTypeGuards'

import { usePurchasedMixPanel } from '../../analytics/hooks/usePurchasedMixPanel'
import { useTryRequestLegacyBilletPayment } from './useTryRequestLegacyBilletPayment'
import { useTryToRequestNupayPayment } from './useTryToRequestNupayPayment'
import { useTryToRequestPayment } from './useTryToRequestPayment'

export function useTryToCreateOrder() {
  const purchasedPixel = usePurchasedPixel()
  const purchaseMixPanel = usePurchasedMixPanel()

  const onPurchaseSuccess = (payload: CheckoutFormPayload) => {
    purchasedPixel(payload)
    purchaseMixPanel({
      paymentMethod: payload.paymentMethod,
    })
  }

  const {
    tryToRequestPayment,
    isError: isErrorCreditCard,
    isLoading: isLoadingCreditCard,
  } = useTryToRequestPayment(onPurchaseSuccess)

  const {
    tryToRequestNupayPayment,
    isError: isErrorNupay,
    isLoading: isLoadingNupay,
  } = useTryToRequestNupayPayment(onPurchaseSuccess)

  const {
    tryRequestLegacyBilletPayment,
    isError: isErrorBillet,
    isLoading: isLoadingBillet,
  } = useTryRequestLegacyBilletPayment(onPurchaseSuccess)

  const tryToCreateOrder = (payload: CheckoutFormPayload) => {
    purchaseMixPanel({
      keyEvent: 'onPurchaseClick',
      paymentMethod: payload.paymentMethod,
    })
    if (isCheckoutFormPayloadWithNupay(payload)) {
      return tryToRequestNupayPayment(payload)
    }

    if (isCheckoutFormPayloadWithBillet(payload)) {
      return tryRequestLegacyBilletPayment(payload)
    }

    return tryToRequestPayment(payload)
  }

  return {
    tryToCreateOrder,
    isError: isErrorBillet || isErrorCreditCard || isErrorNupay,
    isLoading: isLoadingBillet || isLoadingCreditCard || isLoadingNupay,
  }
}
