import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai'

import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'
import { PixPaymentAtoms } from 'src/features/checkout/atoms/PixPaymentAtoms'
import { CheckoutFormPayload } from 'src/features/checkout/typings/checkoutFormTypings'
import { isCheckoutFormPayloadWithPix } from 'src/features/checkout/utils/checkoutFormTypeGuards'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'

import { paymentsApi } from '../apis/paymentApi'
import {
  RequestPaymnentPixResponse,
  TryRequestPaymentPixPayload,
} from '../typings/paymentTypings'
import { requestPixBodyFactories } from '../utils/requestPixBodyFactories'

interface PixPaymentResponse {
  tryRequestLegacyPixPayment: UseMutateAsyncFunction<
    RequestPaymnentPixResponse,
    unknown,
    CheckoutFormPayload,
    unknown
  >
  isLoading: boolean
  data: undefined | RequestPaymnentPixResponse
  reset: () => void
}

export function useTryToRequestPixPayment(): PixPaymentResponse {
  const queryParams = useCheckoutParamsFromUrl()

  const { product } = useProduct()
  const { offer } = useOffer()

  const [, setIsErrorPaymentPix] = useAtom(PixPaymentAtoms.isErrorPaymentPix)

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
  const isDirectCheckout = useAtomValue(checkoutAtoms.isDirectCheckout)
  const sessionId = useAtomValue(checkoutAtoms.sessionId)

  const { data, mutateAsync, isPending, reset } = useMutation({
    mutationFn: async (formPayload: CheckoutFormPayload) => {
      if (!isCheckoutFormPayloadWithPix(formPayload) || !product)
        throw new Error('invalid payload')

      const body: TryRequestPaymentPixPayload =
        await requestPixBodyFactories.toPixOrderFactory(
          queryParams,
          formPayload,
          isNewCheckout,
          isDirectCheckout,
          sessionId,
          offer
        )

      return paymentsApi.tryToCreatePaymentFromPix(body)
    },
    onError: () => {
      setIsErrorPaymentPix(true)
    },
  })

  return {
    tryRequestLegacyPixPayment: mutateAsync,
    isLoading: isPending,
    data,
    reset,
  }
}
