import { FC, memo } from 'react'
import { Control, useFormContext } from 'react-hook-form'

import { useWatchInstallmentsEnabled } from '../hooks/useWatchInstallmentsEnabled'
import { CheckoutFormPayloadOfAllTypes } from '../typings/checkoutFormTypings'

/**
 * Para contornar problemas de performance com watch,
 * movemos o uso para useWatch um nível abaixo e evitando re-renders com
 * o control e memo.
 */
export const InstallmentWatcher = () => {
  const { control } = useFormContext<CheckoutFormPayloadOfAllTypes>()

  return <InstallmentWatcherInner control={control} />
}

const InstallmentWatcherInner: FC<{
  control: Control<CheckoutFormPayloadOfAllTypes>
}> = memo(({ control }) => {
  useWatchInstallmentsEnabled(control)

  return null
})
