import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Box, Flex, Text } from '@queimadiaria/ui'
import React from 'react'
import QRCode from 'react-qr-code'

import { useTryToCreateOrderPix } from 'src/features/payment/hooks/useTryToCreateOrderPix'
import { useTryToVerificationPixPayment } from 'src/features/payment/hooks/useTryToVerificationPixPayment'
import { PixCountdown } from 'src/features/pixCountdown/components/PixCountdown'

import { useCopyFieldFromPix } from '../hooks/useCopyFieldFromPix'
import { SpinnerComponent } from './SpinnerComponent'

export const PixPaymentNewCheckout: React.FC = () => {
  const { isCopyQrCode, handleCopyField } = useCopyFieldFromPix()
  const { isLoading, pixPaymentDetails, isFormDataValid } =
    useTryToCreateOrderPix()
  useTryToVerificationPixPayment(pixPaymentDetails)

  return (
    <>
      {isFormDataValid && (
        <ContainerPix>
          <LeftBox>
            <Box sx={{ marginBottom: '24px' }}>
              <Text variant='subheadline-2' css={{ textAlign: 'center' }}>
                Pagar com Pix é fácil:
              </Text>
            </Box>
            {isLoading ? (
              <Flex
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '277px',
                }}
              >
                <SpinnerComponent />
              </Flex>
            ) : (
              <Box>
                <BoxQR>
                  <QRCode
                    size={177}
                    value={
                      pixPaymentDetails?.qrCode ? pixPaymentDetails.qrCode : ''
                    }
                  />
                </BoxQR>
                <Box
                  sx={{
                    marginBlockStart: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <PixCountdown
                    endsAt={
                      pixPaymentDetails?.expiresAt
                        ? pixPaymentDetails.expiresAt
                        : new Date()
                    }
                  />
                </Box>

                {!isCopyQrCode ? (
                  <CopyQrcodeButton
                    onClick={() =>
                      handleCopyField(
                        pixPaymentDetails?.qrCode
                          ? pixPaymentDetails.qrCode
                          : ''
                      )
                    }
                  >
                    <img src='/assets/copy.svg' alt='copy-icon' />
                    <Text>Copiar código Pix</Text>
                  </CopyQrcodeButton>
                ) : (
                  <CopyQrcodeButton isChecked>
                    <img src='/assets/check.svg' alt='copy-icon' />
                    <Text>Código Pix copiado</Text>
                  </CopyQrcodeButton>
                )}
              </Box>
            )}
          </LeftBox>
          <Line />
          <RigthBox>
            <Box sx={{ width: '100%' }}>
              <BoxListText>
                <ListNumber>
                  <Text color={'#FF4F00'}>1</Text>
                </ListNumber>
                <Text>
                  Selecione o Código Pix no app do seu banco preferencial.
                </Text>
              </BoxListText>
              <BoxListText>
                <ListNumber>
                  <Text color={'#FF4F00'}>2</Text>
                </ListNumber>
                <Text>
                  Escolha uma das opções de pagamento: copie e cole o código ou
                  leia o código com a câmera do celular e autorize o pagamento.
                </Text>
              </BoxListText>
              <BoxListText>
                <ListNumber>
                  <Text color={'#FF4F00'}>3</Text>
                </ListNumber>
                <Text>
                  Você receberá um e-mail após a confirmação do pagamento. Caso
                  não consiga pagar, o pedido será automaticamente cancelado.{' '}
                </Text>
              </BoxListText>
            </Box>
          </RigthBox>
        </ContainerPix>
      )}
    </>
  )
}

const ContainerPix = styled(Flex)`
  width: 100%;
  background-color: #fafafa;
  padding: 16px 32px 32px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const LeftBox = styled(Box)`
  min-width: 200px;
`

const BoxQR = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  padding: 12px;
`

interface CopyQrcodeButtonProps {
  isChecked?: boolean
}

const CopyQrcodeButton = styled(Box)<CopyQrcodeButtonProps>`
  margin-top: 16px;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0061f9;
  cursor: pointer;

  i {
    margin-right: 11px;
  }
  p {
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0125em;
  }

  ${props =>
    props.isChecked &&
    css`
      color: #24a148;
    `}
`

const RigthBox = styled(Box)`
  display: flex;
  align-items: center;
`

const Line = styled.div`
  width: 311px;
  height: 1px;
  margin-bottom: 16px;
  margin-top: 24px;
  background-color: #e0e0e0;
`

const ListNumber = styled.div`
  display: flex;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border: 1px solid #ff4f00;
  border-radius: 12px;
  margin-right: 13px;
`

const BoxListText = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  p {
    max-width: 388px;
  }
  :last-child {
    margin-bottom: 0px;
  }
`
