import { SerializedApiError } from 'src/infra/typings/globalTypings'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fromCurrentUserErrorToSerializedApiError = ({ error }: any) => {
  const serializedError: SerializedApiError = {
    isSerializedApiError: true,
    statusCode: error.statusCode,
    code: error?.data?.operationStatus || error?.data?.status || error?.code,
    message: error.response,
  }

  return serializedError
}
