import styled from '@emotion/styled'
import { useAtomValue } from 'jotai'
import { FC } from 'react'

import { useIsCrossSellCheckout } from 'src/features/crossSellCheckout/hooks/useIsCrossSellCheckout'
import { SubmitDetailsContainerFromNewCheckout } from 'src/features/layout/components/SubmitDetailsNewCheckout'
import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'
import { usePaymentDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/modules/payment/usePaymentDigitalCheckoutContext'

import { SubmitDetailsContainer } from '../../layout/components/SubmitDetails'
import { checkoutAtoms } from '../atoms/checkoutAtoms'

export const CheckoutSubmitDetails: FC = () => {
  const isRedirectingAfterSuccess = useAtomValue(
    paymentAtoms.isRedirectingAfterSuccess
  )

  const isPaymentWithInstallmentsEnabled = useAtomValue(
    checkoutAtoms.isPaymentWithInstallmentsEnabledAtom
  )

  const isMethodBillet = useAtomValue(checkoutAtoms.isPaymentByBillet)
  const { selectedMethodPayment } = usePaymentDigitalCheckoutContext()

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
  const isCrossSellCheckout = useIsCrossSellCheckout()

  if (!isNewCheckout) {
    const submitButtonLabel = isRedirectingAfterSuccess
      ? 'Redirecionando...'
      : isMethodBillet || selectedMethodPayment === 'billet'
      ? 'Gerar boleto'
      : 'Comprar agora'

    return (
      <SubmitDetailsContainer
        submitButtonLabel={submitButtonLabel}
        isInstallmentsEnabled={isPaymentWithInstallmentsEnabled}
      />
    )
  }

  const submitButtonLabel = isRedirectingAfterSuccess
    ? 'Redirecionando...'
    : isMethodBillet
    ? 'Gerar boleto'
    : !isCrossSellCheckout
    ? 'Continuar'
    : 'Comprar agora'

  return (
    <SubmitDetailsContainerFromNewCheckout
      submitButtonLabel={submitButtonLabel}
      isInstallmentsEnabled={isPaymentWithInstallmentsEnabled}
    />
  )
}

export const PlanSmallLogoContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  margin-inline-end: 8px;
`
